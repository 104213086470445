import classNames from 'owa-classnames';
import { observer } from 'owa-mobx-react';
import { getBrowserWidth } from 'owa-config';
import Draggable from 'owa-dnd/lib/components/Draggable';
import { getDensityMode } from 'owa-fabric-theme';
import { useLazyKeydownHandler } from 'owa-hotkeys';
import VirtualizedLoadMoreListView from 'owa-loadmore-listview/lib/components/VirtualizedLoadMoreListView';
import VirtuosoLoadMoreListView from 'owa-loadmore-listview/lib/components/VirtuosoLoadMoreListView';
import type {
    LoadMoreListViewExtendedVirtualizedProps,
    VirtualizedLoadMoreListViewRef,
} from 'owa-loadmore-listview/lib/components/VirtualizedLoadMoreListView';
import { getItem, setItem } from 'owa-local-storage';
import loc from 'owa-localize';
import { resetFocus, tabIndex } from 'owa-mail-focus-manager';
import { isSingleLineListView, shouldShowListView } from 'owa-mail-layout';
import {
    getMailListGroupHeader,
    getMailListGroupHeaderGenerator,
    addVirtualizedGroupHeadersAction,
    getIndicesOfHiddenRowsInCollapsedGroupHeaders,
    getRowKeysFromCollapsedGroupHeaders,
} from 'owa-virtualized-group-headers';
import {
    mailListItemAnimationStore,
    clearAnimationStore,
} from 'owa-mail-list-item-animation-store';
import type { MailListTableProps } from 'owa-mail-list-item-shared';
import { addMailListLog, getMailListLogObjectToAddToStore } from 'owa-mail-list-logging';
import { lazyGetHashedLogString } from 'owa-logging-utils/lib/lazyFunctions';
import type { TableView, MailFolderTableQuery } from 'owa-mail-list-store';
import {
    updateStartAndEndIndicesInVLV,
    getFocusedFilterForTable,
    getListViewState,
    getIsEverythingSelectedInTable,
    getItemsOrConversationsSelectedText,
    type TableQueryType,
    getSelectedTableView,
    isDigestFilter,
} from 'owa-mail-list-store';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import getMailListRowDragData from 'owa-mail-list-store/lib/utils/getMailListRowDragData';
import { MessageAdList } from 'owa-mail-messagead-list-view';
import {
    AnswersContainer,
    isAnswerFeatureEnabled,
    LoadMoreTopResultsComponent,
} from 'owa-mail-search';
import { getStore as getMailSearchStore } from 'owa-mail-search/lib/store/store';
import {
    getCanTableLoadMore,
    getCanTableLoadMorePrevious,
    lazyLoadMoreInTable,
} from 'owa-mail-triage-table-load-extra';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import { useMergedRefs } from 'owa-react-hooks/lib/useMergedRefs';
import { useWindowEvent } from 'owa-react-hooks/lib/useWindowEvent';
import type FocusedViewFilter from 'owa-service/lib/contract/FocusedViewFilter';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import React, { useState } from 'react';
import getMailListTableProps from '../utils/getMailListTableProps';
import {
    getMailListDragPreview,
    MAIL_ITEM_DRAG_XOFFSET,
    MAIL_ITEM_DRAG_YOFFSET,
} from '../utils/mailListDragUtil';
import { runListAnimation } from '../utils/runListAnimation';
import {
    lazyLogMailListItemPreloadData,
    lazySetupMailListContentKeys,
} from './lazy/lazyListViewModule';
import {
    draggableDiv,
    wrapperDiv,
    outerScrollContainer,
    isSingleLineView,
    rowWrapper,
    spinnerPlaceHolder,
} from './MailList.scss';

import { messageListAriaLabel } from './MailListContent.locstring.json';
import MailListGroupHeader from './MailListGroupHeader';
import MailListItemDataProvider from './MailListItemDataProvider';
import MailListLoading from './MailListLoading';
import { assertNever } from 'owa-assert';
import { computed } from 'mobx';
import type MailListItemContextMenuState from 'owa-mail-list-store/lib/store/schema/MailListItemContextMenuState';
import useMailListItemDragEvents from '../hooks/useMailListItemDragEvents';
import dragToDownloadEmail from 'owa-attachment-drag-and-drop/lib/utils/dragToDownloadEmail';
import { isFeatureEnabled } from 'owa-feature-flags';
import type { ListViewLoadingDirection } from 'owa-mail-loading-action-types';

const BOOTSTRAP_MAILLISTITEM_WINDOW_WIDTH = 'bootstrapMailListItemWindowWidth';
const BOOTSTRAP_MAILLISTITEM_VIEW_SWAP_SETTING = 'bootstrapMailListItemViewSwapSetting';

export interface MailListContentProps {
    isFocusOnMailList: () => boolean;
    getIsFocusInMailList: () => boolean;
    tableViewId: string;
    tableView: TableView;
    onScroll?: (scrollingRegion: HTMLDivElement) => void;
    shouldShowTopMessageAdList: boolean;
    shouldShowFloatingMessageAdList: boolean;
    plannedMiddleMessageAdRow: number;
    styleSelectorAsPerUserSettings: string;
    itemContextMenuState: MailListItemContextMenuState | null;
}

function useFocusManager(
    props: Pick<
        MailListContentProps,
        'getIsFocusInMailList' | 'isFocusOnMailList' | 'itemContextMenuState'
    >,
    trySetFocus: () => boolean,
    tableView: TableView
) {
    React.useEffect(() => {
        // If the listview loads without cached content, only MailList is displayed.
        // When the mail list content gets rendered later, it needs to transfer focus from MailList to MailListContent
        // to support keyboard navigation.
        /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
         * BASELINE. DO NOT COPY AND PASTE!
         *	> 'requestAnimationFrame' is restricted from being used. Use safeRequestAnimationFrame in owa-performance instead. */
        window.requestAnimationFrame(() => {
            const isFocusOnMailListComponent =
                document.activeElement === document.getElementById('MailList');
            if (isFocusOnMailListComponent && !props.itemContextMenuState) {
                trySetFocus();
            }
        });
    });

    React.useEffect(() => {
        // Bug 180055 React.useEffect cleanup can't reliably be used to conditionally move focus away from an "about to unmount" focused element
        return () => {
            if (props.getIsFocusInMailList()) {
                // Reset focus such that it can be restored when component is updated
                resetFocus('MailListContentMount');
            }
        };
    }, []);

    const currentShowListPane = shouldShowListView();
    const lastShowListPane = React.useRef<boolean>();
    const lastTableViewId = React.useRef<string>();
    React.useEffect(() => {
        // When list pane changes from shown to hidden, it means we have gone into immersive reading pane and we need to
        // save current scroll position for if user exits and goes back to same list pane (in same table). When user
        // exits immersive reading pane and returns to the same table / previously shown list pane which changes list pane
        // from hidden to shown, we need to reset focus
        if (
            lastShowListPane.current !== undefined &&
            lastShowListPane.current !== currentShowListPane &&
            lastTableViewId.current === tableView.id
        ) {
            resetFocus('MailListContextMenuHideListView');
        }

        lastShowListPane.current = currentShowListPane;
        lastTableViewId.current = tableView.id;
    }, [currentShowListPane]);
}

function useMemoizedMailListTableProps(tableViewId: string) {
    // Because the value depends on computed properties, we can't simply use React.useMemo()
    // with just tableViewId as a dependency. However, we do need to use useMemo to keep the
    // object reference the same
    return React.useMemo(
        () => getMailListTableProps(tableViewId),
        [...Object.values(getMailListTableProps(tableViewId))]
    );
}

export default observer(
    React.forwardRef(function MailListContent(
        props: MailListContentProps,
        ref: React.Ref<HTMLDivElement>
    ) {
        const {
            tableViewId,
            tableView,
            shouldShowTopMessageAdList,
            shouldShowFloatingMessageAdList,
            styleSelectorAsPerUserSettings,
        } = props;
        const mailListViewContentRef = React.useRef<HTMLDivElement>(null);
        const containerRef = useMergedRefs(mailListViewContentRef, ref);

        const listViewRef = React.useRef<VirtualizedLoadMoreListViewRef>(null);
        const propagateResizeTask = React.useRef<number>();

        const mailListTableProps = useMemoizedMailListTableProps(tableViewId);
        const mailListGroupHeaderGenerator = useComputedValue(
            () => getMailListGroupHeaderGenerator(tableView),
            [tableView]
        );
        const trySetFocus = React.useCallback((): boolean => {
            if (mailListViewContentRef.current) {
                mailListViewContentRef.current.focus();

                return true;
            }
            return false;
        }, []);

        /* eslint-disable-next-line owa-custom-rules/prefer-react-state-without-arrays-or-objects -- (https://aka.ms/OWALintWiki)
         * Please remove the array or object from React.useState() or leave a justification in case is not possible to do so.
         *	> It is preferable not to use arrays or objects as react state, use primitive data types, useReducer or satchel state instead, if its possible. */
        const [rowKeysInCollapsedSections, setRowKeysInCollapsedSections] = useState<string[]>([]);

        const [onDragStart, onDragEnd] = useMailListItemDragEvents();

        const onDragStartFunc = React.useCallback(
            (dataTransfer: DataTransfer): void => {
                onDragStart();
                const dragData = getMailListRowDragData();
                // Drag and Drop for download is only supported for single item selection
                if (dragData?.latestItemIds?.[0]) {
                    dragToDownloadEmail(
                        dataTransfer,
                        dragData.latestItemIds[0],
                        dragData.subjects[0]
                    );
                }
            },
            [mailListTableProps]
        );

        React.useEffect(() => {
            setRowKeysInCollapsedSections([]);
            addVirtualizedGroupHeadersAction(tableView, true /* shouldReset */);
        }, [tableView.id]);

        const onSectionCollapsed = React.useCallback(() => {
            setRowKeysInCollapsedSections(getRowKeysFromCollapsedGroupHeaders());
        }, [tableView?.rowPositionUpdatedCount]);

        const hiddenRowIndices = React.useMemo((): number[] => {
            return getIndicesOfHiddenRowsInCollapsedGroupHeaders(tableView);
        }, [tableView, rowKeysInCollapsedSections, tableView?.rowPositionUpdatedCount]);

        // Log correctness of the preload optimization after initial render.
        React.useEffect(() => {
            /**
             * Determine params here so the proper state is used (as the util is
             * lazy and the state will have been updated already by the time it's
             * called).
             */
            const params = {
                previousSessionBrowserWidth: parseInt(
                    getItem(window, BOOTSTRAP_MAILLISTITEM_WINDOW_WIDTH) ?? '0'
                ),
                currentSessionBrowserWidth: getBrowserWidth(),
                currentSessionViewMode: mailListTableProps.isSingleLine ? 'slv' : '3col',
                previousSessionViewSwapSetting:
                    getItem(window, BOOTSTRAP_MAILLISTITEM_VIEW_SWAP_SETTING) ?? 'none',
            };

            lazyLogMailListItemPreloadData.importAndExecute(params);
        }, []);

        /**
         * Stores user's view and browser width in local storage to be used on
         * next boot so the proper component is preloaded.
         */
        React.useEffect(() => {
            setItem(window, BOOTSTRAP_MAILLISTITEM_WINDOW_WIDTH, getBrowserWidth().toString());
        }, [mailListTableProps.isSingleLine]);

        useLazyKeydownHandler(
            mailListViewContentRef,
            lazySetupMailListContentKeys.importAndExecute,
            tableViewId
        );

        React.useEffect(() => {
            if (mailListItemAnimationStore.pendingAnimations.length > 0) {
                runListAnimation(listViewRef, tableView);
            }
        }, [mailListItemAnimationStore.pendingAnimations, tableView]);

        React.useEffect(() => {
            // Effect body intentionally blank. Here, we return clearAnimationStore as
            // the cleanup side-effect. The returned method is invoked by React when
            // the DependencyList contains changes or the component is unmounted
            // e.g. (folder switch, filter/sort change, search, module switch, etc.)
            return function cleanup() {
                clearAnimationStore();
            };
        }, [tableView.id]);

        // We want to update rowKeysInCollapsedSections for the message list
        // anytime currentLoadedIndex and loadedStartIndex changes because new
        // rows are being added into the list and we should evaluate whether they
        // are displayed or not based on whether they belong to a collapsed or
        // expanded group header.
        React.useEffect(() => {
            addVirtualizedGroupHeadersAction(tableView, false /* shouldReset */);
            setRowKeysInCollapsedSections(getRowKeysFromCollapsedGroupHeaders());
        }, [
            tableView.id,
            tableView.rowKeys.length,
            tableView.currentLoadedIndex,
            tableView.loadedStartIndex,
            tableView.reloadCount,
            tableView.rowPositionUpdatedCount,
        ]);

        // Propagate resize task to avoid calling too many times
        useWindowEvent(
            'resize',
            () => {
                if (propagateResizeTask.current) {
                    window.clearTimeout(propagateResizeTask.current);
                    propagateResizeTask.current = undefined;
                }

                propagateResizeTask.current = window.setTimeout(() => {
                    propagateResizeTask.current = undefined;
                }, 150);
            },
            'MailListContent',
            []
        );

        useFocusManager(props, trySetFocus, tableView);

        const getCanCurrentTableLoadMore = React.useCallback((): boolean => {
            return mailListItemAnimationStore.activeAnimations.length > 0 ||
                mailListItemAnimationStore.pendingAnimations.length > 0 // When animating we dont want to load more rows because it can effect the position of other items and the layout.
                ? false
                : getCanTableLoadMore(tableView);
        }, [
            tableView,
            mailListItemAnimationStore.activeAnimations,
            mailListItemAnimationStore.pendingAnimations,
        ]);

        const getCanCurrentTableLoadMorePrevious = React.useCallback((): boolean => {
            return mailListItemAnimationStore.activeAnimations.length > 0 ||
                mailListItemAnimationStore.pendingAnimations.length > 0 // When animating we dont want to load more rows because it can effect the position of other items and the layout.
                ? false
                : getCanTableLoadMorePrevious(tableView);
        }, [
            tableView,
            mailListItemAnimationStore.activeAnimations,
            mailListItemAnimationStore.pendingAnimations,
        ]);

        const getAriaLabel = () => {
            let ariaLabel = loc(messageListAriaLabel);

            if (getIsEverythingSelectedInTable(tableView) || tableView.selectedRowKeys.size == 0) {
                ariaLabel += ' ' + getItemsOrConversationsSelectedText(tableViewId);
            }

            return ariaLabel;
        };

        /**
         * Clear tabindex of container such that container is not in a sequential
         * keyboard navigation when an item is selected in listview
         */
        const resetListViewContentTabIndex = React.useCallback(() => {
            const mailListViewContent = mailListViewContentRef.current;
            if (mailListViewContent?.getAttribute('tabindex') === tabIndex.sequentialIndex) {
                mailListViewContent.setAttribute('tabindex', tabIndex.nonSequentialIndex);
            }
        }, []);

        const onLoadMoreRows = React.useCallback(
            (loadingDirection: ListViewLoadingDirection) => {
                if (!tableView.isLoading) {
                    lazyLoadMoreInTable.importAndExecute(tableView, loadingDirection, listViewRef);
                }
            },
            [tableView]
        );

        const canDrag = React.useCallback(() => {
            return mailListTableProps.canDragFromTable;
        }, [mailListTableProps.canDragFromTable]);

        const isDraggable = React.useCallback(() => {
            const dragData = getMailListRowDragData();
            return !!dragData && dragData.rowKeys.length > 0 && mailListTableProps.isDraggable;
        }, [mailListTableProps.isDraggable]);

        const isAnswerAvailable = getMailSearchStore().isAnswerAvailable;

        const isScrollPerfEnabled = React.useMemo(() => isFeatureEnabled('fwk-scroll-perf'), []);
        const createMailListItem = React.useCallback(
            (
                rowKey: string,
                itemIndex: number,
                listProps: MailListTableProps,
                // this will skip rendering of things that don't need to be there if it will only be on the screen for a short time
                // such as event listeners and hover effects
                renderMinimum: boolean
            ) => {
                // The unique key must be provided to the outermost element, otherwise React will
                // default to using the list index. This would be VERY bad as any row delete/additions
                // will cause a re-render
                if (
                    tableView.rowKeys.includes(rowKey) &&
                    !rowKeysInCollapsedSections.includes(rowKey)
                ) {
                    return (
                        <React.Fragment key={`item_${rowKey}`}>
                            <MailListItemDataProvider
                                rowKey={rowKey}
                                mailListTableProps={listProps}
                                resetListViewContentTabIndex={resetListViewContentTabIndex}
                                getIsFocusInMailList={props.getIsFocusInMailList}
                                styleSelectorAsPerUserSettings={styleSelectorAsPerUserSettings}
                                renderMinimum={renderMinimum && isScrollPerfEnabled}
                            />
                            {isAnswerFeatureEnabled() &&
                                tableView.tableQuery.type === 1 &&
                                itemIndex !== 0 && (
                                    <AnswersContainer
                                        tableQuery={tableView.tableQuery}
                                        itemIndex={itemIndex}
                                    ></AnswersContainer>
                                )}
                            {tableView.tableQuery.type === 1 &&
                                (isFeatureEnabled('sea-topResultsExpansion-7') ||
                                    isFeatureEnabled('sea-topResultsExpansion-10')) && (
                                    <LoadMoreTopResultsComponent
                                        tableView={tableView}
                                        totalRowKeys={tableView.rowKeys.length}
                                        itemIndex={itemIndex}
                                    />
                                )}
                        </React.Fragment>
                    );
                } else {
                    return null;
                }
            },
            [
                tableView.id,
                tableView.rowKeys.length,
                resetListViewContentTabIndex,
                props.getIsFocusInMailList,
                styleSelectorAsPerUserSettings,
                isAnswerAvailable,
                rowKeysInCollapsedSections.length,
            ]
        );

        const createHeader = React.useCallback(
            (previousRowKey: string | null, currentRowKey: string) => {
                const headers: JSX.Element[] = [];
                const headerText = getMailListGroupHeader(
                    previousRowKey,
                    currentRowKey,
                    tableView,
                    mailListGroupHeaderGenerator
                );

                if (headerText && !isDigestFilter(tableView?.tableQuery as MailFolderTableQuery)) {
                    headers.push(
                        <MailListGroupHeader
                            key={`gh_${headerText}`}
                            headerText={headerText}
                            groupHeaderStylesAsPerUserSettings={styleSelectorAsPerUserSettings}
                            tableView={tableView}
                            onCollapse={onSectionCollapsed}
                        />
                    );

                    lazyGetHashedLogString.import().then(getHashedLogString => {
                        addMailListLog(
                            getMailListLogObjectToAddToStore('GroupHeaderAddedToListView', {
                                tableViewId: tableView.id,
                                headerText: getHashedLogString(headerText),
                                previousRowKey,
                                currentRowKey,
                            })
                        );
                    });
                }

                if (headers.length === 0) {
                    return null;
                }

                return <React.Fragment key={`header_${currentRowKey}`}>{headers}</React.Fragment>;
            },
            [mailListGroupHeaderGenerator, tableView, styleSelectorAsPerUserSettings]
        );

        const loadingSpinner = React.useMemo((): JSX.Element => {
            return <MailListLoading />;
        }, []);

        const focusedRowKeyIndex = React.useMemo((): number => {
            const focusedRowKey = tableView.focusedRowKey;
            return focusedRowKey ? tableView.rowKeys.indexOf(focusedRowKey) : -1;
        }, [tableView.focusedRowKey, tableView.rowKeys]);

        const getDragData = React.useCallback(() => {
            const mailListRowDragData = getMailListRowDragData();
            if (mailListRowDragData) {
                return mailListRowDragData;
            }
            return {
                itemType: null,
            };
        }, []);

        const subComponentProps: MailVirtualizedSubComponentProps = React.useMemo(
            () => ({
                tableView,
                shouldShowTopMessageAdList,
                shouldShowFloatingMessageAdList,
                styleSelectorAsPerUserSettings,
                isAnswerAvailable,
                getCanCurrentTableLoadMore,
            }),
            [
                tableView,
                shouldShowTopMessageAdList,
                shouldShowFloatingMessageAdList,
                styleSelectorAsPerUserSettings,
                isAnswerAvailable,
                getCanCurrentTableLoadMore,
            ]
        );

        const renderListViewContent = (): JSX.Element => {
            const listProps: LoadMoreListViewExtendedVirtualizedProps = {
                estimatedRowHeight: getEstimatedRowHeight(),
                updateStartAndEndIndices: updateStartAndEndIndicesInVLV,
                activeAnimationsCount: mailListItemAnimationStore.activeAnimations.length,
                hiddenRowIndices,
            };
            const loadMoreListView = (
                <div role="region" className={wrapperDiv}>
                    <div
                        tabIndex={0}
                        role="listbox"
                        aria-multiselectable={true}
                        aria-label={getAriaLabel()}
                        ref={containerRef}
                        className={wrapperDiv}
                        aria-activedescendant={tableView.focusedRowKey ?? undefined}
                    >
                        {isFeatureEnabled('tri-virtuoso') ? (
                            <VirtuosoLoadMoreListView
                                ref={listViewRef}
                                className={classNames(
                                    'customScrollBar',
                                    outerScrollContainer,
                                    isSingleLineListView() && isSingleLineView
                                )}
                                dataSourceId={tableView.id}
                                itemIds={tableView.rowKeys}
                                focusedRowKeyIndex={focusedRowKeyIndex}
                                focusedNodeId={
                                    getListViewState().expandedConversationViewState.focusedNodeId
                                }
                                onScroll={props.onScroll}
                                onRenderRow={createMailListItem}
                                onRenderHeader={createHeader}
                                onLoadMoreRows={onLoadMoreRows}
                                isLoadRowsInProgress={tableView.isLoading}
                                getCanLoadMore={getCanCurrentTableLoadMore}
                                getCanLoadMorePrevious={getCanCurrentTableLoadMorePrevious}
                                currentLoadedIndex={tableView.currentLoadedIndex}
                                loadedStartIndex={tableView.loadedStartIndex}
                                loadingComponent={loadingSpinner}
                                rowWrapperClass={rowWrapper}
                                PreRowsComponent={PreRows}
                                MidRowsComponent={MidRows}
                                midRowsComponentRowNumber={props.plannedMiddleMessageAdRow}
                                PostRowsComponent={PostRows}
                                listProps={mailListTableProps}
                                subComponentProps={subComponentProps}
                                {...listProps}
                            />
                        ) : (
                            <VirtualizedLoadMoreListView
                                ref={listViewRef}
                                className={classNames(
                                    'customScrollBar',
                                    outerScrollContainer,
                                    isSingleLineListView() && isSingleLineView
                                )}
                                dataSourceId={tableView.id}
                                itemIds={tableView.rowKeys}
                                focusedRowKeyIndex={focusedRowKeyIndex}
                                focusedNodeId={
                                    getListViewState().expandedConversationViewState.focusedNodeId
                                }
                                onScroll={props.onScroll}
                                onRenderRow={createMailListItem}
                                onRenderHeader={createHeader}
                                onLoadMoreRows={onLoadMoreRows}
                                isLoadRowsInProgress={tableView.isLoading}
                                getCanLoadMore={getCanCurrentTableLoadMore}
                                getCanLoadMorePrevious={getCanCurrentTableLoadMorePrevious}
                                currentLoadedIndex={tableView.currentLoadedIndex}
                                loadedStartIndex={tableView.loadedStartIndex}
                                loadingComponent={loadingSpinner}
                                rowWrapperClass={rowWrapper}
                                PreRowsComponent={PreRows}
                                MidRowsComponent={MidRows}
                                midRowsComponentRowNumber={props.plannedMiddleMessageAdRow}
                                PostRowsComponent={PostRows}
                                listProps={mailListTableProps}
                                subComponentProps={subComponentProps}
                                {...listProps}
                            />
                        )}
                    </div>
                </div>
            );

            return loadMoreListView;
        };

        return (
            <Draggable
                canDrag={canDrag}
                isDraggable={isDraggable}
                onDragStart={onDragStartFunc}
                onDragEnd={onDragEnd}
                getDragData={getDragData}
                getDragPreview={getMailListDragPreview}
                xOffset={MAIL_ITEM_DRAG_XOFFSET}
                yOffset={MAIL_ITEM_DRAG_YOFFSET}
                classNames={draggableDiv}
            >
                {renderListViewContent()}
            </Draggable>
        );
    }),
    'MailListContent'
);

interface MailVirtualizedSubComponentProps {
    tableView: TableView;
    shouldShowTopMessageAdList: boolean;
    shouldShowFloatingMessageAdList: boolean;
    styleSelectorAsPerUserSettings: string;
    isAnswerAvailable: boolean;
    getCanCurrentTableLoadMore: () => boolean;
}

const PreRows = observer(function PreRows({
    tableView,
    shouldShowTopMessageAdList,
    shouldShowFloatingMessageAdList,
}: MailVirtualizedSubComponentProps) {
    return (
        <>
            {isAnswerFeatureEnabled() && !isSingleLineListView() && (
                <AnswersContainer
                    itemIndex={0}
                    tableQuery={tableView.tableQuery}
                ></AnswersContainer>
            )}
            {shouldShowTopMessageAdList && (
                <MessageAdList
                    loadInFocusedPivot={getFocusedFilterForTable(tableView) == 0}
                    loadInOtherPivot={getFocusedFilterForTable(tableView) == 1}
                    loadInEmptyPivot={false}
                    loadInItemView={tableView.tableQuery.listViewType == 1}
                    loadOnHeader={false}
                    requestingAnotherFloatingAd={shouldShowFloatingMessageAdList}
                    middleFloatingAdOnly={false}
                    shouldShowTwoAds={
                        getFocusedFilterForTable(tableView) == 1 && !shouldShowFloatingMessageAdList
                    }
                    tableView={tableView}
                    adIndex={0}
                />
            )}
        </>
    );
},
'PreRows');

const MidRows = observer(function MidRows({
    tableView,
    shouldShowTopMessageAdList,
    shouldShowFloatingMessageAdList,
}: MailVirtualizedSubComponentProps) {
    return (
        <>
            {shouldShowFloatingMessageAdList && (
                <MessageAdList
                    loadInFocusedPivot={getFocusedFilterForTable(tableView) == 0}
                    loadInOtherPivot={getFocusedFilterForTable(tableView) == 1}
                    loadInEmptyPivot={false}
                    loadInItemView={tableView.tableQuery.listViewType == 1}
                    loadOnHeader={false}
                    requestingAnotherFloatingAd={shouldShowTopMessageAdList}
                    middleFloatingAdOnly={!shouldShowTopMessageAdList}
                    shouldShowTwoAds={false}
                    tableView={tableView}
                    adIndex={shouldShowTopMessageAdList ? 1 : 0}
                />
            )}
        </>
    );
},
'MidRows');

const PostRows = observer(function PostRows({
    tableView,
    isAnswerAvailable,
    getCanCurrentTableLoadMore,
}: MailVirtualizedSubComponentProps) {
    return (
        /* For cases where there are more answers to render than mail search results
    render all the remaining answers together at the end */
        <>
            {isAnswerFeatureEnabled() && isAnswerAvailable && (
                <AnswersContainer
                    tableQuery={tableView.tableQuery}
                    totalRowKeys={tableView.rowKeys.length}
                ></AnswersContainer>
            )}
            <SpinnerPlaceholder
                isLoading={tableView.isLoading}
                getCanCurrentTableLoadMore={getCanCurrentTableLoadMore}
            />
        </>
    );
},
'PostRows');

const SpinnerPlaceholder = observer(function SpinnerPlaceholder(props: {
    isLoading: boolean;
    getCanCurrentTableLoadMore: () => boolean;
}): JSX.Element | null {
    // Do not show the placeholder if we're loading and showing the spinner, or this table
    // cannot load more which means the spinner won't ever appear.
    if (props.isLoading || !props.getCanCurrentTableLoadMore()) {
        return null;
    }

    // Create an element to render after all rows that take up the same space as the loading indicator,
    // so that the height of the scroll region doesn't change when loading indicator appears
    return <div className={spinnerPlaceHolder} />;
},
'SpinnerPlaceholder');

const computedEstimatedRowHeight = computed((): number => {
    const densityMode = getDensityMode();
    const isJunkFolder =
        folderIdToName(getSelectedTableView()?.tableQuery?.folderId ?? '') === 'junkemail';

    if (isSingleLineListView()) {
        switch (densityMode) {
            case 'Full':
                return 36.796875;
            case 'Simple':
                return 32.796875;
            case 'Compact':
                return 25;
            default:
                assertNever(densityMode);
        }
    } else {
        switch (densityMode) {
            case 'Full':
                return isJunkFolder ? 100 : 80.8;
            case 'Simple':
                return isJunkFolder ? 92 : 72.796875;
            case 'Compact':
                return isJunkFolder ? 79 : 62.796875;
            default:
                assertNever(densityMode);
        }
    }
});

const getEstimatedRowHeight = () => computedEstimatedRowHeight.get();
