import type {
    AttachmentPickers,
    ComposeRibbonControlDefProps,
    ReadOnlyRibbonControlDefProps,
} from 'owa-mail-ribbon-utils';
import type { RibbonTabDefinitionBase, RibbonTabDefinitionSet } from 'owa-acui';
import { propsBaseGlobal } from '../utils/ribbonDefinitionProps';
import type { ComposeViewState } from 'owa-mail-compose-store/lib/store/schema/ComposeViewState';
import { owaComputedFn } from 'owa-computed-fn';
import { getComposeViewState } from 'owa-mail-reading-pane-store-conversation/lib/utils/getComposeViewState';
import getSLRHomeTab from './slr-home-tab/getSLRHomeTab';
import getSLRNotesTab from './slr-notes-tab/getSLRNotesTab';
import getSLRViewTab from './slr-view-tab/getSLRViewTab';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isNotesScenario } from 'owa-mail-ribbon-utils/lib/isNotesScenario';
import { getRibbonMailboxInfo } from 'owa-mail-ribbon-utils/lib/getRibbonMailboxInfo';
import { getHelpTab } from 'owa-ribbon-help';
import { getSelectedTab } from 'owa-mail-ribbon-store/lib/store/store';
import type { MailboxInfo } from 'owa-client-types';
import { lazyGetSLRComposeTabs } from '../../lazyComposeFunctions';

let composePropsGlobal: ComposeRibbonControlDefProps | undefined;
let prevAttachmentPickers: AttachmentPickers | undefined;
let prevViewState: ComposeViewState | undefined;

// Clarification: "readOnly" does not mean that this property never has its properties changed.
// "readOnly" in this context means the mail state, so "not compose".
// Importantly, mailboxInfo can change and we track that change.
let readOnlyPropsGlobal: ReadOnlyRibbonControlDefProps = {
    isPopout: false,
    mailboxInfo: undefined,
    itemId: undefined,
    projectionTabId: undefined,
    selectedRowKey: undefined,
};

export const getMailRibbonDefinitionsSLR = owaComputedFn(function getMailRibbonDefinitionsSLR(
    attachmentPickers: AttachmentPickers
): RibbonTabDefinitionSet {
    const tabs: RibbonTabDefinitionBase[] = [];

    if (isFeatureEnabled('mon-ribbon-customization-multiAccount')) {
        // Check if we need to update readOnlyProps for Mailbox Info
        const mailboxInfo: MailboxInfo = getRibbonMailboxInfo(
            undefined /* RibbonControlDefinitionProps */
        );
        if (mailboxInfo !== readOnlyPropsGlobal.mailboxInfo) {
            readOnlyPropsGlobal = {
                ...readOnlyPropsGlobal,
                mailboxInfo,
            };
        }
    }

    tabs.push(
        getSLRHomeTab(readOnlyPropsGlobal),
        getSLRViewTab(readOnlyPropsGlobal),
        getHelpTab(getSelectedTab())
    );

    const viewState: ComposeViewState | undefined = getComposeViewState();
    if (viewState?.isComposeRendered) {
        // Check if we need to update compose props
        if (
            !composePropsGlobal ||
            !prevAttachmentPickers ||
            prevAttachmentPickers !== attachmentPickers ||
            !prevViewState ||
            prevViewState !== viewState ||
            composePropsGlobal.mailboxInfo !== viewState.mailboxInfo
        ) {
            prevAttachmentPickers = attachmentPickers;
            prevViewState = viewState;
            composePropsGlobal = {
                ...propsBaseGlobal,
                composeViewState: viewState,
                attachmentPickers,
                mailboxInfo: viewState.mailboxInfo,
            };
        }

        /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
         * BASELINE. Do not copy and paste!
         *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
        const getSLRComposeTabs = lazyGetSLRComposeTabs.tryImportForRender();
        if (getSLRComposeTabs) {
            tabs.push(...getSLRComposeTabs(composePropsGlobal));
        }
    }

    if (isNotesScenario()) {
        tabs.push(getSLRNotesTab(readOnlyPropsGlobal));
    }

    return {
        tabs,
    };
});
