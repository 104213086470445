import { owaComputedFn } from 'owa-computed-fn';
import { getUserConfiguration, isShadowMailboxUser } from 'owa-session-store';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import { isReadingPaneConversationEnabled } from 'owa-mail-unstacked';
import getStore from '../store/store';
import type ViewType from '../store/schema/ViewType';
import { isFeatureEnabled } from 'owa-feature-flags';

const shouldShowUnstackedReadingPane = owaComputedFn(() => {
    // Do not show unstacked reading pane for shadow mailbox users
    if (isShadowMailboxUser(getModuleContextMailboxInfo())) {
        return false;
    }

    if (isFeatureEnabled('rp-momocoSettings')) {
        const store = getStore();

        // If the new settings are not initialized, fallback to the legacy settings as a hint
        if (store.readingPaneViewType != 3 && store.messageListExpansionType != 3) {
            return (
                store.readingPaneViewType == 2 &&
                (store.messageListExpansionType == 1 || store.messageListExpansionType == 0)
            );
        }
    }

    const globalListViewTypeReact = getUserConfiguration()?.UserOptions?.GlobalListViewTypeReact;
    return globalListViewTypeReact == 0 && !isReadingPaneConversationEnabled();
});

export default shouldShowUnstackedReadingPane;
