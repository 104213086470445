import React from 'react';
import loc from 'owa-localize';
import { observer } from 'owa-mobx-react';
import {
    Button,
    Popover,
    PopoverSurface,
    PopoverTrigger,
    Tooltip,
} from '@fluentui/react-components';
import type { OpenPopoverEvents, OnOpenChangeData } from '@fluentui/react-components';
import { ArrowCurveDownLeftRegular } from '@fluentui/react-icons';
import getMasterCategoryList from 'owa-categories/lib/utils/getMasterCategoryList';
import { isSingleLineListView } from 'owa-mail-layout';
import { getDensityModeCssClass } from 'owa-fabric-theme';
import { getStore as getListViewStore, type SortColumn } from 'owa-mail-list-store';
import type { MailFolderTableQuery } from 'owa-mail-list-store';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';
import { jumpToButtonLabel } from './MailListJumpToPopover.locstring.json';
import {
    jumpToButton,
    jumpToButtonWithLabel,
    jumpToButtonOpened,
    jumpToIcon,
    popoverSurface,
    full,
    medium,
    compact,
} from './MailListJumpToPopover.scss';
import { LazyJumpToCalloutContent } from '../lazyComponents';

import classnames from 'owa-classnames';

export interface MailListJumpToPopoverProps {
    tableViewId: string;
}

export default observer(function MailListJumpToPopover(props: MailListJumpToPopoverProps) {
    const tableView = getListViewStore().tableViews.get(props.tableViewId);
    const mailboxInfo = getMailboxInfo(tableView);
    const showJumpToButtonLabel = isSingleLineListView();
    const sortColumn = (tableView?.tableQuery as MailFolderTableQuery)?.sortBy?.sortColumn;
    const categoryList = getMasterCategoryList(mailboxInfo);
    const densityMode = getDensityModeCssClass(full, medium, compact);

    const [open, setOpen] = React.useState(false);

    const handleOpenChange = React.useCallback((_e: OpenPopoverEvents, data: OnOpenChangeData) => {
        setOpen(data.open || false);
    }, []);

    const onClose = React.useCallback(() => {
        setOpen(false);
    }, []);

    // If the jump to button is being used to display the label, then we don't want
    // to wrap it in a Tooltip. Otherwise, we do so that we can display the label
    // when the user hovers over the button.
    const menuButton = React.useMemo(() => {
        const jumpToButtonIcon = (
            <ArrowCurveDownLeftRegular className={classnames(jumpToIcon, densityMode)} />
        );

        if (showJumpToButtonLabel) {
            return (
                <Button
                    icon={jumpToButtonIcon}
                    className={classnames(
                        jumpToButtonWithLabel,
                        open && jumpToButtonOpened,
                        densityMode
                    )}
                >
                    {loc(jumpToButtonLabel)}
                </Button>
            );
        } else {
            return (
                <Tooltip content={loc(jumpToButtonLabel)} relationship="label">
                    <Button
                        icon={jumpToButtonIcon}
                        className={classnames(
                            jumpToButton,
                            open && jumpToButtonOpened,
                            densityMode
                        )}
                    />
                </Tooltip>
            );
        }
    }, [showJumpToButtonLabel, open, densityMode]);

    if (!tableView || (sortColumn === 14 && categoryList.length === 0)) {
        return null;
    }

    return (
        <>
            <Popover
                positioning={'below-start'}
                trapFocus={true}
                open={open}
                onOpenChange={handleOpenChange}
                closeOnScroll={true}
            >
                <PopoverTrigger disableButtonEnhancement={true}>{menuButton}</PopoverTrigger>
                <PopoverSurface className={popoverSurface}>
                    {open && (
                        <LazyJumpToCalloutContent
                            mailboxInfo={mailboxInfo}
                            tableView={tableView}
                            onClose={onClose}
                        />
                    )}
                </PopoverSurface>
            </Popover>
        </>
    );
}, 'MailListJumpToPopover');
