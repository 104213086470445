import { LazyImport, LazyModule } from 'owa-bundling';

export const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "ActionControlsSections"*/ './lazyIndex'),
    { name: 'ActionControlsSections' }
);

export const lazyGetTableSections = new LazyImport(lazyModule, m => m.getTableSections);
export const lazyGetLinkUnlinkSections = new LazyImport(lazyModule, m => m.getLinkUnlinkSections);
export const lazyGetBoostSections = new LazyImport(lazyModule, m => m.getBoostSections);
export const lazyGetDictationLanguagePickerMenuSections = new LazyImport(
    lazyModule,
    m => m.getDictationLanguagePickerMenuSections
);
