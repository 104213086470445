import { isFeatureEnabled } from 'owa-feature-flags';
import type { SearchScope } from 'owa-search-service/lib/data/schema/SearchScope';
import { SearchScopeKind } from 'owa-search-service/lib/data/schema/SearchScope';
import { SearchProvider } from 'owa-search-service/lib/data/schema/SearchProvider';
import { isOffline } from 'owa-offline/lib/isOffline';
import { isOfflineSyncEnabled, isOfflineSearchEnabled } from 'owa-offline-sync-feature-flags';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { logUsage } from 'owa-analytics';
import shouldForceOfflineSearchBasedOnTimestamp from './shouldForceOfflineSearchBasedOnTimestamp';
import { getScenarioStore, SearchScenarioId } from 'owa-search-store';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { isAccountExplicitLogon } from 'owa-account-shared-mailbox-utils';

// Cache to store search providers for each search session
// Exported so tests can access it
export const searchProviderCache: Map<string, SearchProvider> = new Map();

export default function getSearchProvider(
    searchScope: SearchScope,
    clearSessionGuidFromCache: boolean = false,
    isOfflineFallback?: boolean
): SearchProvider {
    const scopeKind = searchScope?.kind;
    const searchSessionGuid = getScenarioStore(SearchScenarioId.Mail).searchSessionGuid;
    const shouldWriteToCache = !!(scopeKind && searchSessionGuid);
    const mailboxInfo = getModuleContextMailboxInfo();

    // Remove the session guid from this cache if the search provider needs to be recomputed
    if (clearSessionGuidFromCache && searchSessionGuid) {
        searchProviderCache.delete(searchSessionGuid);
    }

    // If the user has opted into a timeframe of offline search or has fallen back to offline search, return offline provider
    const shouldForceOfflineSearch = shouldForceOfflineSearchBasedOnTimestamp();
    if (isOfflineFallback || (shouldForceOfflineSearch && scopeKind !== SearchScopeKind.PstFile)) {
        if (shouldWriteToCache) {
            searchProviderCache.set(searchSessionGuid, SearchProvider.Offline);
        }
        return SearchProvider.Offline;
    }

    if (searchProviderCache.has(searchSessionGuid)) {
        const searchProviderFromCache = searchProviderCache.get(searchSessionGuid);
        if (searchProviderFromCache) {
            return searchProviderFromCache;
        }
    }

    if (scopeKind === SearchScopeKind.PstFile) {
        if (shouldWriteToCache) {
            searchProviderCache.set(searchSessionGuid, SearchProvider.PST);
        }
        return SearchProvider.PST;
    }

    if (
        (isOfflineSearchEnabled(mailboxInfo) && isOffline()) ||
        isFeatureEnabled('sea-localSearchOverride')
    ) {
        const searchProvider = SearchProvider.Offline;
        if (shouldWriteToCache) {
            searchProviderCache.set(searchSessionGuid, searchProvider);
        }
        return searchProvider;
    }

    if (scopeKind === SearchScopeKind.SharedFolders) {
        const searchProvider = SearchProvider.SubstrateV2;
        if (shouldWriteToCache) {
            searchProviderCache.set(searchSessionGuid, searchProvider);
        }
        return searchProvider;
    }

    if (scopeKind === SearchScopeKind.PublicFolder) {
        const searchProvider = SearchProvider.FindItem;
        if (shouldWriteToCache) {
            searchProviderCache.set(searchSessionGuid, searchProvider);
        }
        return searchProvider;
    }

    if (isAccountExplicitLogon(mailboxInfo)) {
        const searchProvider = SearchProvider.SubstrateV2;
        if (shouldWriteToCache) {
            searchProviderCache.set(searchSessionGuid, searchProvider);
        }
        return searchProvider;
    }

    /**
     * Key off of the staticSearchScope to determine which search provider to use.
     * This is determined by whether or not 3S supports the given scenario.
     */
    if (
        scopeKind === SearchScopeKind.PrimaryMailbox ||
        scopeKind === SearchScopeKind.ArchiveMailbox ||
        scopeKind === SearchScopeKind.Group
    ) {
        const searchProvider = SearchProvider.SubstrateV2;
        if (shouldWriteToCache) {
            searchProviderCache.set(searchSessionGuid, searchProvider);
        }
        return searchProvider;
    }

    // Default to 3Sv2, but do not cache it. Log instances so we can determine when this default is being hit.
    logUsage('GetSearchProvider_DefaultTo3Sv2', {
        scopeKind,
        searchSessionGuid,
        isOffline: isOffline(),
        isOfflineSyncEnabled: isOfflineSyncEnabled(getGlobalSettingsAccountMailboxInfo()),
    });
    return SearchProvider.SubstrateV2;
}
