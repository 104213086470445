import { LazyModule, LazyAction, createLazyComponent } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "DiagnosticsAndSupport" */ './lazyIndex'),
    { name: 'DiagnosticsAndSupport' }
);

// Delay loaded components for in-app support
export const lazyHandleSupportPaneEvents = new LazyAction(
    lazyModule,
    m => m.handleSupportPaneEvents
);
export const lazyCloseMonarchAndLaunchLegacyApp = new LazyAction(
    lazyModule,
    m => m.closeMonarchAndLaunchLegacyApp
);
export const lazyToggleOffToLegacyApp = new LazyAction(lazyModule, m => m.toggleOffToLegacyApp);
export const lazyInitializeSupportOnBoot = new LazyAction(
    lazyModule,
    m => m.initializeSupportOnBoot
);
export const lazyRenderSupportPane = new LazyAction(lazyModule, m => m.renderSupportPane);
export const lazyOnSupportPaneOpen = new LazyAction(lazyModule, m => m.onSupportPaneOpen);
export const lazyOnSupportPaneClose = new LazyAction(lazyModule, m => m.onSupportPaneClose);

export const lazyRenderFeedbackPane = new LazyAction(lazyModule, m => m.renderFeedbackPane);
export const lazyLaunchInContextFeedback = new LazyAction(
    lazyModule,
    m => m.launchInContextFeedback
);

export const lazyRenderGetDiagnosticsPane = new LazyAction(
    lazyModule,
    m => m.renderGetDiagnosticsPane
);

export const lazyRenderFeedbackDialog = new LazyAction(lazyModule, m => m.renderFeedbackDialog);

export const lazyLaunchInContextSupport = new LazyAction(lazyModule, m => m.launchInContextSupport);

export const lazyLaunchSupportInContainer = new LazyAction(
    lazyModule,
    m => m.launchSupportInContainer
);
export const lazySendMicroFeedback = new LazyAction(lazyModule, m => m.sendMicroFeedback);

export const FeedbackDialog = createLazyComponent(lazyModule, m => m.FeedbackDialog);

export const UpvoteFeedback = createLazyComponent(lazyModule, m => m.UpvoteFeedback);

export const NpsDialog = createLazyComponent(lazyModule, m => m.NpsDialog);

export const lazyGetIframeUrl = new LazyAction(lazyModule, m => m.getIframeUrl);

export const lazySetClosePane = new LazyAction(lazyModule, m => m.setClosePane);

export const lazySetSwitchToTeamsCalendar = new LazyAction(
    lazyModule,
    m => m.setSwitchToTeamsCalendar
);

export const lazyLoadNpsCopilotView = new LazyAction(lazyModule, m => m.loadNpsCopilotView);

// Non-lazy util functions
export { isSupportEnabled } from './utils/utils';

// Constants
export {
    feedbackScenarioName,
    eventifyAppName,
    bookableTimeScenarioName,
    bookingsAppName,
    bookabletimeAppName,
    bookingsMobileFeedbackScenario,
    entrypointStringActiveWin32User,
    monarchTeamsToggleFeedbackScenario,
    monarchTeamsAppName,
} from './exportedConstants';
