import type { MailboxInfo } from 'owa-client-types';
import {
    type ImplementedCopilotFeatures,
    type CopilotSettingsStore,
    shouldDisableCopilot,
} from 'owa-copilot-settings-store';
import { getApplicationSettings } from 'owa-application-settings';
import checkLanguage from './checkLanguage';
import createStoreCheckForGenericEnablement from './createStoreCheckForGenericEnablement';
import { isFeatureEnabled } from 'owa-feature-flags';
import {
    getCoprincipalMailboxInfo,
    getGlobalSettingsAccountMailboxInfo,
} from 'owa-account-source-list-store';
import isOptInExperienceEnabledForPrioritize from './isOptInExperienceEnabledForPrioritize';
import { getIsCopilotUIDisabled } from 'owa-mail-copilot-settings/lib/selectors/getIsCopilotUIDisabled';

const isCopilotFeatureEnabled = createStoreCheckForGenericEnablement<
    ImplementedCopilotFeatures,
    boolean
>('CopilotEnabled', isCopilotFeatureEnabledInternal);

export default isCopilotFeatureEnabled;

function isCopilotFeatureEnabledInternal(
    store: CopilotSettingsStore,
    language: string,
    scenario: ImplementedCopilotFeatures,
    mailboxInfo: MailboxInfo,
    skipLanguageCheck?: boolean,
    shouldIgnoreUserPreferences?: boolean
) {
    // We also need to have a check here as boot cache will bypass fetchCopilotSettings and directly dump settings into store.
    if (shouldDisableCopilot(mailboxInfo)) {
        return false;
    }

    if (
        isFeatureEnabled('mon-copilot-user-preferences') &&
        !shouldIgnoreUserPreferences &&
        getIsCopilotUIDisabled(mailboxInfo)
    ) {
        return false;
    }

    if (store.isEnabled) {
        const featureEnabled = store.featureInfo.get(scenario);
        if (featureEnabled?.isEnabled) {
            const coprincipalMailboxInfo =
                mailboxInfo.type === 'GroupMailbox'
                    ? getCoprincipalMailboxInfo(mailboxInfo) ??
                      getGlobalSettingsAccountMailboxInfo()
                    : mailboxInfo;

            return (
                isEnabledInECS(scenario, coprincipalMailboxInfo) &&
                (skipLanguageCheck || checkLanguage(language, featureEnabled.langauges))
            );
        }
    }
    return false;
}

function isEnabledInECS(scenario: ImplementedCopilotFeatures, mailboxInfo: MailboxInfo): boolean {
    // retrieve application settings for the specific feature as a small performance optimization
    switch (scenario) {
        case 'AttachmentSummarization':
            return getApplicationSettings('Copilot', mailboxInfo).attachmentSummarizationEnabled;
        case 'AttendanceSuggestion':
            return getApplicationSettings('Copilot', mailboxInfo).attendanceSuggestionEnabled;
        case 'Elaborate':
            return getApplicationSettings('Copilot', mailboxInfo).elaborateEnabled;
        case 'Summarize':
            return getApplicationSettings('Copilot', mailboxInfo).summarizeEnabled;
        case 'Coach':
        case 'CoachApply':
            return getApplicationSettings('Copilot', mailboxInfo).coachEnabled;
        case 'SuggestedDrafts':
            return getApplicationSettings('Copilot', mailboxInfo).suggestedDraftsEnabled;
        case 'SuggestCategory':
            return isFeatureEnabled('time-copilot-categories-row-v2');
        case 'ExplainPlacesReport':
            return true; // client side feature flag.
        case 'Rewrite':
            return isFeatureEnabled('mon-copilot-elaborate-rewrite');
        case 'MeetingPrepSummary':
            // Used by FetchCopilotSettings - Service: https://ecs.skype.com/OlkCopilot/OlkCopilot/configurations/1116121
            // getApplicationSettings('Copilot', mailboxInfo).meetingPrepSummaryEnabled - Client: https://ecs.skype.com/Substrate/OutlookWebClient/configurations/1092852
            return getApplicationSettings('Copilot', mailboxInfo).meetingPrepSummaryEnabled;
        case 'ReplyByMeeting':
            // Used by FetchCopilotSettings - Service: https://ecs.skype.com/OlkCopilot/OlkCopilot/configurations/1123944
            // getApplicationSettings('Copilot', mailboxInfo).meetingPrepSummaryEnabled - Client: https://ecs.skype.com/Substrate/OutlookWebClient/configurations/1092852
            return getApplicationSettings('Copilot', mailboxInfo).replyByMeetingEnabled;
        case 'Chat':
            // Used by FetchCopilotSettings - Service: https://ecs.skype.com/OlkCopilot/OlkCopilot/configurations/1098830
            return true; // Rely on license check for Chat.
        case 'Inbox':
            return (
                // https://ecs.skype.com/Substrate/OutlookWebClient/configurations/1092852
                getApplicationSettings('Copilot', mailboxInfo).prioritizeEnabled &&
                !isOptInExperienceEnabledForPrioritize(mailboxInfo)
            );
        case 'TimeCopilotChat':
            return getApplicationSettings('Copilot', mailboxInfo).timeCopilotChatEnabled;
        case 'VisualTheming':
            return isFeatureEnabled('fwk-copilot-themes');
        case 'MChat':
            return true; // Rely on license check for MChat.
        case 'AutoResourceBooking':
            // https://ecs.skype.com/Substrate/OutlookWebClient/configurations/1092852
            return getApplicationSettings('Copilot', mailboxInfo).autoResourceBookingEnabled;
        case 'BizChatHandoff':
            /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
             * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
             *	> The parameter mailboxInfo must be undefined. Feature flight: 'cmp-copilot-bizchat' */
            return isFeatureEnabled('cmp-copilot-bizchat', mailboxInfo);
        case 'AttendeeColocation':
            // Service: https://ecs.skype.com/OlkCopilot/OlkCopilot/configurations/1569405
            // Client: https://ecs.skype.com/Substrate/OutlookWebClient/configurations/1092852
            return getApplicationSettings('Copilot', mailboxInfo).attendeeColocationEnabled;
        default:
            return false;
    }
}
