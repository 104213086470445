import type { EntityRequestRefiner } from '../../data/schema/SubstrateSearchRequest';
import type DynamicRefinerQueryType from 'owa-service/lib/contract/DynamicRefinerQueryType';

export default function convertDynamicRefinersToRefiners(
    dynamicRefiners: DynamicRefinerQueryType[]
): EntityRequestRefiner[] {
    const refinersCount = dynamicRefiners ? dynamicRefiners.length : 0;

    if (refinersCount === 0) {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (13,9): Type 'null' is not assignable to type 'EntityRequestRefiner[]'.
        // @ts-expect-error
        return null;
    }

    return dynamicRefiners
        .filter(
            (dynamicRefiner: DynamicRefinerQueryType) => dynamicRefiner.RefinerQuery !== undefined
        )
        .map((dynamicRefiner: DynamicRefinerQueryType) => {
            return {
                RefinerString: dynamicRefiner.RefinerQuery ?? '',
            };
        });
}
