import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isLocalDataBackedMailboxInfo } from 'owa-account-source-list/lib/utils/isLocalDataBackedAccount';
import { getAccountScopeUserSettings } from 'owa-session-store';
import type OutlookDataFileProperty from 'owa-service/lib/contract/OutlookDataFileProperty';
import { isGulpOrBranching } from 'owa-config';

/**
 * Check if the mailboxes are supported for Move/Copy cross account
 * Covers the following scenarios:
 * - From PST to PST
 * - From online account to PST file
 * - From PST file to online account
 * - From online account to online account
 * @param sourceMailboxInfo Source mailbox info
 * @param destinationMailboxInfo Destination mailbox info
 */
export function checkMailboxesAreSupportedForCrossAccount(
    sourceMailboxInfo: MailboxInfo,
    destinationMailboxInfo: MailboxInfo
) {
    const isSourceMailboxPST = isLocalDataBackedMailboxInfo(sourceMailboxInfo);
    const isDestinationMailboxPST = isLocalDataBackedMailboxInfo(destinationMailboxInfo);

    // Scenario 1: From PST to PST - Checks if the feature is PST enabled
    if (isSourceMailboxPST && isDestinationMailboxPST) {
        // PST Flight have to be enabled for Global Account
        return isFeatureEnabled('acct-pstFileImportExport');
    }

    /* Scenario 2: From online account to PST file
       Checks:
        - If the OutlookDataFile property is different than deny or noGrow for sourceMailboxInfo
        - If the PST flight is enabled for sourceMailboxInfo
    */
    if (!isSourceMailboxPST && isDestinationMailboxPST) {
        const outlookDataFilePolicy =
            getAccountScopeUserSettings(sourceMailboxInfo)?.PolicySettings?.OutlookDataFile;
        const isOutlookDataFileMoveAllowed =
            outlookDataFilePolicy === 0 ||
            outlookDataFilePolicy === 1 ||
            outlookDataFilePolicy === 3;
        return (
            isOutlookDataFileMoveAllowed &&
            /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
             * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
             *	> The parameter mailboxInfo must be undefined. Feature flight: 'acct-pstFileImportExport' */
            isFeatureEnabled('acct-pstFileImportExport', sourceMailboxInfo)
        );
    }

    /* Scenario 3: From PST file to online account
        Checks:
         - If the OutlookDataFile property is different than deny for destinationMailboxInfo
         - If the PST flight is enabled for destinationMailboxInfo
    */
    if (isSourceMailboxPST && !isDestinationMailboxPST) {
        const isOutlookDataFileMoveAllowed =
            getAccountScopeUserSettings(destinationMailboxInfo)?.PolicySettings?.OutlookDataFile !==
            5;
        return (
            isOutlookDataFileMoveAllowed &&
            /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
             * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
             *	> The parameter mailboxInfo must be undefined. Feature flight: 'acct-pstFileImportExport' */
            isFeatureEnabled('acct-pstFileImportExport', destinationMailboxInfo)
        );
    }

    // If we are in Gulp or Branching, we allow cross account move/copy without checking policies
    // so we can test with our mailboxes (Micrsoft and consumer accounts)
    const satisfyPolicySettings =
        isGulpOrBranching() ||
        (!!getAccountScopeUserSettings(sourceMailboxInfo)?.PolicySettings
            ?.ItemsToOtherAccountsEnabled &&
            !!getAccountScopeUserSettings(destinationMailboxInfo)?.PolicySettings
                ?.ItemsToOtherAccountsEnabled);

    // Scenario 4: From online account to online account. Features and policies are checked for both mailboxes
    return (
        /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
         * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
         *	> The parameter mailboxInfo must be undefined. Feature flight: 'mon-move-copy-crossAccount' */
        isFeatureEnabled('mon-move-copy-crossAccount', sourceMailboxInfo) &&
        /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
         * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
         *	> The parameter mailboxInfo must be undefined. Feature flight: 'mon-move-copy-crossAccount' */
        isFeatureEnabled('mon-move-copy-crossAccount', destinationMailboxInfo) &&
        satisfyPolicySettings
    );
}
