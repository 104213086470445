import { LazyImport, LazyModule, createLazyComponent, LazyAction } from 'owa-bundling';
import store from './store/store';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailFolderTreeContainerView" */ './lazyIndex'),
    { name: 'MailFolderTreeContainerView' }
);

export const lazyGetPrimaryMailboxRootContextMenuItems = new LazyImport(
    lazyModule,
    m => m.getPrimaryMailboxRootContextMenuItems
);
export const ArchiveMailFolderTreeContainer = createLazyComponent(
    lazyModule,
    m => m.ArchiveMailFolderTreeContainer
);
export const SharedFolderTreeParentContainer = createLazyComponent(
    lazyModule,
    m => m.SharedFolderTreeParentContainer
);
export const SharedFolderTree = createLazyComponent(lazyModule, m => m.SharedFolderTree);
export const MailFolderContextMenu = createLazyComponent(lazyModule, m => m.MailFolderContextMenu);

export const LazyPrimaryMailboxRootContextMenuItems = createLazyComponent(
    lazyModule,
    m => m.PrimaryMailboxRootContextMenuItems
);

export const LazyCustomFolderOrderCallout = createLazyComponent(
    lazyModule,
    m => m.CustomFolderOrderCallout
);

export const LazyMailFolderDialogsWrapper = createLazyComponent(
    lazyModule,
    m => m.MailFolderDialogsWrapper
);

export const lazySetShouldShowMoveToFolderDialog = new LazyAction(
    lazyModule,
    m => m.setShouldShowMoveToFolderDialogMutator
);

export const LazyMoveToFolderDialog = createLazyComponent(
    lazyModule,
    m => m.MoveToFolderDialog,
    undefined,
    undefined,
    { onlyImportIf: () => store.shouldShow }
);
