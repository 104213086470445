import type TimeZoneRangeType from 'owa-service/lib/contract/TimeZoneRangeType';
import type TimeZoneEntry from 'owa-service/lib/contract/TimeZoneEntry';
import type TimeZoneOffsetsType from 'owa-service/lib/contract/TimeZoneOffsetsType';
import getTimeZoneOffsets from 'owa-timezone-loader/lib/getTimeZoneOffsets';
import { updateAllTimeZones } from '../mutators/allTimeZonesMutators';
import { setTimeZoneRange } from '../mutators/timeZoneRangesMutators';
import getTimeZoneOperation from 'owa-service/lib/operation/getTimeZoneOperation';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import getMailboxRequestOptions from 'owa-service/lib/getMailboxRequestOptions';
import { errorThatWillCauseAlert, type TraceErrorObject } from 'owa-trace';
import { setTimeZoneRangeLoadState, timeZoneRangeLoadState } from '../store';
import { setItem, getItem } from 'owa-local-storage';
import { runAfterInitialRender } from 'owa-bundling-light/lib/utils/delayLoad';
import { GovernPriority } from 'owa-client-types/lib/GovernPriority';
import { scrubForPii } from 'owa-config';
import { isFeatureEnabled } from 'owa-feature-flags';

export type GetTimeZonesFn = () => Promise<TimeZoneEntry[] | undefined>;
export type GetTimeZoneOffsetsFn = () => Promise<TimeZoneOffsetsType[] | undefined>;
export type GetTimeZoneOverrides = {
    getTimeZones: GetTimeZonesFn;
    getTimeZoneOffsets: GetTimeZoneOffsetsFn;
};

const timeZoneCacheKey = 'isTimeZoneCacheAvailable';
// TODO VSO 83431: seperate timezone logic out of owa-datetime-store
export async function initializeTimeZones(
    shouldInitializeTimeZoneAnonymously?: boolean,
    getTimeZoneOverrides?: GetTimeZoneOverrides
): Promise<[TimeZoneOffsetsType[] | undefined, TimeZoneEntry[] | undefined]> {
    const timeZoneOffsetsPromise = initializeTimeZoneOffsets(
        shouldInitializeTimeZoneAnonymously,
        getTimeZoneOverrides
    );
    const allTimeZonesPromise = initializeAllTimeZones(
        shouldInitializeTimeZoneAnonymously,
        getTimeZoneOverrides
    );

    return Promise.all([timeZoneOffsetsPromise, allTimeZonesPromise]);
}

async function initializeAllTimeZones(
    shouldInitializeTimeZoneAnonymously?: boolean,
    getTimeZoneOverrides?: GetTimeZoneOverrides
): Promise<TimeZoneEntry[] | undefined> {
    let timeZoneList: TimeZoneEntry[] | undefined;
    if (shouldInitializeTimeZoneAnonymously) {
        timeZoneList = [];
    } else {
        if (
            getTimeZoneOverrides &&
            !isTimeZoneAvaiableFromCache(shouldInitializeTimeZoneAnonymously)
        ) {
            timeZoneList = await getTimeZoneOverrides.getTimeZones().catch(e => {
                /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
                 * The error name (message) must be a string literal (no variables in it).
                 *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
                errorThatWillCauseAlert(e);
                return undefined;
            });
        }
        if (!timeZoneList) {
            timeZoneList = (
                await getTimeZoneOperation(
                    {
                        needTimeZoneList: true,
                    },
                    getMailboxRequestOptions(getGlobalSettingsAccountMailboxInfo())
                ).catch(e => {
                    /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
                     * The error name (message) must be a string literal (no variables in it).
                     *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
                    errorThatWillCauseAlert(e);
                    return { TimeZoneList: [] };
                })
            ).TimeZoneList;
        }
    }
    updateAllTimeZones(timeZoneList as Required<TimeZoneRangeType>[]);

    return timeZoneList;
}
async function initializeTimeZoneOffsets(
    shouldInitializeTimeZoneAnonymously?: boolean,
    getTimeZoneOverrides?: GetTimeZoneOverrides
): Promise<TimeZoneOffsetsType[] | undefined> {
    setTimeZoneRangeLoadState('Initializing');
    if (typeof shouldInitializeTimeZoneAnonymously !== 'undefined') {
        let timeZoneOffsets;
        try {
            if (
                getTimeZoneOverrides &&
                !isTimeZoneAvaiableFromCache(shouldInitializeTimeZoneAnonymously)
            ) {
                timeZoneOffsets = await getTimeZoneOverrides.getTimeZoneOffsets().catch(e => {
                    setTimeZoneRangeLoadState('RequestFailure');
                    errorThatWillCauseAlert('gettimezoneOffsets call failure', {
                        ...e,
                    });
                    return undefined;
                });
            }
            if (!timeZoneOffsets) {
                timeZoneOffsets = await getTimeZoneOffsets().catch(e => {
                    setTimeZoneRangeLoadState('RequestFailure');
                    /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
                     * The error name (message) must be a string literal (no variables in it).
                     *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
                    errorThatWillCauseAlert(e);
                    return undefined;
                });
            }
        } catch (err) {
            setTimeZoneRangeLoadState('RequestFailure');
            const exception: TraceErrorObject = new Error(
                'UnhandledExceptionInitializeTimeZoneOffsets'
            );
            exception.additionalInfo = {
                shouldInitializeTimeZoneAnonymously,
                hasGetTimeZoneOverrides: !!getTimeZoneOverrides,
                innerMessage: scrubForPii(err.message),
                innerStack: scrubForPii(err.stack),
            };
            errorThatWillCauseAlert(exception);
        }

        if (timeZoneOffsets == undefined || timeZoneOffsets.length == 0) {
            // don't overwrite RequestFailure
            if (timeZoneRangeLoadState == 'Initializing') {
                setTimeZoneRangeLoadState('ResponseEmpty');
                errorThatWillCauseAlert('Empty getTimeZoneOffsets response');
            }
            return timeZoneOffsets;
        }

        const validTimeZoneOffsets = timeZoneOffsets.filter(tzo => tzo.TimeZoneId);
        if (validTimeZoneOffsets.length !== timeZoneOffsets.length) {
            errorThatWillCauseAlert('Invalid TimeZoneRange');
        }
        setTimeZoneRange(validTimeZoneOffsets);
        setTimeZoneRangeLoadState('Initialized');
        return timeZoneOffsets;
    } else {
        setTimeZoneRangeLoadState('Skipped');
        return undefined;
    }
}

const isTimeZoneAvaiableFromCache = (shouldInitializeTimeZoneAnonymously?: boolean) => {
    if (!isFeatureEnabled('timezone-localcache-disabled') && !getItem(self, timeZoneCacheKey)) {
        // Workaround to avoid perf regression. Temporary code to cache timezone data in service worker for our offline users.
        // This workaround be removed in a few weeks once cache is available for the majority of the Teams offline timezone data users.
        runAfterInitialRender(() => {
            const promises: Promise<any>[] = [getTimeZoneOffsets()];
            if (!shouldInitializeTimeZoneAnonymously) {
                promises.push(
                    getTimeZoneOperation(
                        {
                            needTimeZoneList: true,
                        },
                        getMailboxRequestOptions(getGlobalSettingsAccountMailboxInfo())
                    )
                );
            }
            return Promise.all(promises).then(() => {
                setItem(self, timeZoneCacheKey, 'true');
            });
        }, GovernPriority.Default);
        return false;
    }
    return true;
};
