import getStore from '../store/store';
import { isFeatureEnabled } from 'owa-feature-flags';
import type { MailboxInfo } from 'owa-client-types';
import { isAccountExplicitLogon } from 'owa-account-shared-mailbox-utils';

export const getIsCustomSortEnabled = (mailboxInfo: MailboxInfo) => {
    const isExplicitLogon = isAccountExplicitLogon(mailboxInfo);
    return (
        !isExplicitLogon &&
        /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
         * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
         *	> The parameter mailboxInfo must be undefined. Feature flight: 'fwk-folders-custom-sorted-on-boot' */
        isFeatureEnabled('fwk-folders-custom-sorted-on-boot', mailboxInfo) &&
        !getStore().isAlphabetizeFolderList
    );
};
