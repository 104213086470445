import { logUsage } from 'owa-analytics';

let isPenSupported: boolean = false;

export function setTextPenSupported() {
    logUsage('TextPenSupported');
    isPenSupported = true;
}

export function isTextPenSupported(): boolean {
    return isPenSupported;
}
