import { getSelectedTableView } from 'owa-mail-list-store';
import { SearchScopeKind } from 'owa-search-service/lib/data/schema/SearchScope';
import type { SearchTableQuery } from 'owa-mail-list-search';
import { isFolderInMailboxType } from 'owa-folders';

/**
 * Determine if the ongoing search is for a shared folder
 * @returns boolean
 */
export default function isSharedFolderSearch(): boolean {
    const tableQuery = getSelectedTableView()?.tableQuery;

    if (!tableQuery) {
        return false;
    }

    const searchTableQuery = tableQuery as SearchTableQuery;
    if (searchTableQuery.searchScope) {
        return searchTableQuery.searchScope.kind === SearchScopeKind.SharedFolders;
    } else {
        // Until user performs a search the searchScope does not get set. So, we need this fallback check
        const { folderId } = searchTableQuery;
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (20,9): Type 'string | boolean' is not assignable to type 'boolean'.
        // @ts-expect-error
        return folderId && isFolderInMailboxType(folderId, 'SharedMailbox');
    }
}
