import React from 'react';

import type { MailFolderFromServiceFragment } from 'owa-mail-folder-view-graphql';
import {
    PRIMARY_DELETED_ITEMS_DISTINGUISHED_ID,
    PRIMARY_FOLDERS_TREE_TYPE,
    PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID,
    SHARED_FOLDER_ROOT_DISTINGUISHED_ID,
    type LoadMoreFoldersActionSource,
} from 'owa-folders-constants';
import folderStore, {
    FolderTreeLoadStateEnum,
    lazyFetchMoreFolders,
    getFolderTreeHasMoreData,
    getFolderTreeCurrentLoadedIndex,
    getFolderTreeLoadingState,
    getPrimaryFolderTreeRootFolder,
} from 'owa-folders';
import {
    animationContainer,
    contentContainer,
} from 'owa-expansion-animation/lib/styles/expansionAnimationStyles.scss';
import { transitionStyles } from 'owa-expansion-animation';
import { CSSTransition } from 'react-transition-group';
import { useManagedMutation } from 'owa-apollo-hooks';

import { DeleteFolderDocument } from 'owa-folder-deletefolder/lib/graphql/__generated__/DeleteFolderMutation.interface';
import { Folders } from './PrimaryMailFolderTreeContainer.locstring.json';
import { MailFolderContextMenu, LazyCustomFolderOrderCallout } from '../lazyFunctions';
import { MailFolderListWrapper } from './MailFolderListWrapper';
import { MailFolderRoot } from 'owa-mail-folder-view';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { MoveFolderDocument } from 'owa-folder-movefolder/lib/graphql/__generated__/MoveFolderMutation.interface';
import { RenameFolderDocument } from 'owa-folder-rename/lib/graphql/__generated__/RenameFolderMutation.interface';
import { ToggleFolderTreeCollapsedDocument } from '../graphql/__generated__/ToggleFolderTreeCollapsedStateMutation.interface';
import { isMonarchMultipleAccountsEnabled } from 'owa-account-source-list/lib/flights';
import { lazyMoveFolder } from 'owa-folder-movefolder';
import loc from 'owa-localize';
import { observer } from 'owa-mobx-react';
import { onDeleteFolder } from '../utils/onDeleteFolder';
import { toggleFolderTreeCollapsed } from '../operations/toggleFolderTreeCollapsed';
import { getStore } from 'owa-mail-folder-store/lib/store/store';
import { getTryShowCustomOrderCallout } from 'owa-mail-folder-store/lib/selectors/getTryShowCustomOrderCallout';
import doMailboxInfoIndexersMatch from '../utils/doMailboxInfoIndexersMatch';
import { PerformanceDatapoint } from 'owa-analytics';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isSharedOrDelegateMailbox } from '../utils/isSharedOrDelegateMailbox';
import { isCapabilitySupported } from 'owa-capabilities';
import { favoritesCapability } from 'owa-capabilities-definitions/lib/favoritesCapability';

const lightningId = 'CustomFolderOrderCallout';

function getRootFolderId(mailboxInfo: MailboxInfo): string {
    if (isSharedOrDelegateMailbox(mailboxInfo)) {
        return SHARED_FOLDER_ROOT_DISTINGUISHED_ID;
    }

    return PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID;
}

export interface PrimaryMailFolderTreeContainerProps {
    showRootNode: boolean;
    isMailRootFolderTreeCollapsed: boolean | null | undefined;
    userEmailAddress: string | null | undefined;
    isExplicitLogon: boolean | null | undefined;
    isShadowMailbox: boolean;
    mailboxInfo: MailboxInfo;
    isExpanded?: boolean;
    style?: React.CSSProperties;
    ellipsesOnHover?: boolean;
    rootElementRef?: React.RefObject<HTMLDivElement>;
}

/**
 * Primary mailbox container
 */
export default observer(function PrimaryMailFolderTreeContainer(
    props: PrimaryMailFolderTreeContainerProps
) {
    const accountRef = React.createRef<HTMLDivElement>();
    const [moveFolderMutation] = useManagedMutation(MoveFolderDocument);
    const [toggleFolderTreeCollapsedMutation] = useManagedMutation(
        ToggleFolderTreeCollapsedDocument
    );
    const [deleteFolderMutation] = useManagedMutation(DeleteFolderDocument);
    const [renameFolderMutation] = useManagedMutation(RenameFolderDocument);

    const {
        mailboxInfo,
        isExpanded,
        rootElementRef,
        showRootNode,
        isMailRootFolderTreeCollapsed,
        userEmailAddress,
        isExplicitLogon,
    } = props;

    const isTreeExpanded = React.useMemo(() => {
        return isExpanded ?? !isMailRootFolderTreeCollapsed;
    }, [isExpanded, isMailRootFolderTreeCollapsed]);

    const onRootNodeChevronClicked = React.useCallback(() => {
        toggleFolderTreeCollapsed(toggleFolderTreeCollapsedMutation, !isTreeExpanded, mailboxInfo);
    }, [isTreeExpanded, mailboxInfo]);

    const moveFolder = React.useCallback(
        (
            destinationFolderId: string,
            destinationFolderMailboxInfo: MailboxInfo,
            sourceFolderId: string,
            sourceFolderMailboxInfo: MailboxInfo,
            sourceFolderParentFolderId: string,
            sourceFolderDisplayName: string
        ) => {
            lazyMoveFolder.importAndExecute(
                moveFolderMutation,
                destinationFolderId /*destinationFolderId */,
                destinationFolderMailboxInfo /* destinationFolderMailboxInfo */,
                sourceFolderId /* sourceFolderId */,
                sourceFolderMailboxInfo /* sourceFolderMailboxInfo */,
                sourceFolderParentFolderId /* parentFolderId */,
                sourceFolderDisplayName
            );
        },
        []
    );

    const deleteFolder = React.useCallback(
        (folderId: string, isSearchFolder?: boolean, distinguishedFolderParentIds?: string[]) => {
            onDeleteFolder(
                folderId,
                PRIMARY_DELETED_ITEMS_DISTINGUISHED_ID,
                mailboxInfo,
                'Keyboard' /* actionSource */,
                deleteFolderMutation,
                renameFolderMutation,
                isSearchFolder,
                distinguishedFolderParentIds
            );
        },
        [mailboxInfo]
    );

    const rootDisplayName = isMonarchMultipleAccountsEnabled()
        ? userEmailAddress || loc(Folders)
        : loc(Folders);

    const isFavoritesSupported = isCapabilitySupported(favoritesCapability, mailboxInfo);

    const rootFolder = getPrimaryFolderTreeRootFolder(mailboxInfo);
    const shouldShowLoadingSpinner =
        getFolderTreeLoadingState(getRootFolderId(props.mailboxInfo), mailboxInfo) ===
        FolderTreeLoadStateEnum.Loading;
    const hasMoreData = getFolderTreeHasMoreData(getRootFolderId(props.mailboxInfo), mailboxInfo);
    const offset = getFolderTreeCurrentLoadedIndex(getRootFolderId(props.mailboxInfo), mailboxInfo);

    const fetchMoreData = React.useCallback(
        async (_currentOffset: number) => {
            const perfDp = new PerformanceDatapoint('FP_FetchMorePrimaryMailFolders');
            const fetchMoreFolders = await lazyFetchMoreFolders.import();
            await fetchMoreFolders(mailboxInfo, 'PrimaryFolderPane', perfDp);
        },
        [mailboxInfo]
    );

    const indexer = getIndexerValueForMailboxInfo(mailboxInfo);
    const key = 'primarymailboxtree_' + indexer;
    const rootId = 'primaryMailboxRoot_' + indexer;
    const showCustomOrderCallout =
        !isExplicitLogon &&
        /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
         * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
         *	> The parameter mailboxInfo must be undefined. Feature flight: 'fwk-folders-custom-sorted-on-boot' */
        isFeatureEnabled('fwk-folders-custom-sorted-on-boot', mailboxInfo) &&
        isTreeExpanded &&
        (rootElementRef || showRootNode) &&
        getTryShowCustomOrderCallout(mailboxInfo);

    return (
        <>
            {/* Render tree root */}
            {showRootNode && (
                <MailFolderRoot
                    elementRef={accountRef}
                    id={rootId}
                    displayName={rootDisplayName}
                    onRootNodeChevronClickedCallback={onRootNodeChevronClicked}
                    rootFolder={rootFolder as MailFolderFromServiceFragment}
                    rootNodeId={getRootFolderId(props.mailboxInfo)}
                    shouldBeDroppable={true}
                    treeType={PRIMARY_FOLDERS_TREE_TYPE}
                    isRootExpanded={isTreeExpanded}
                    mailboxInfo={mailboxInfo}
                    shouldShowRootNodeContextMenu={true}
                    moveFolder={moveFolder}
                    style={!!isTreeExpanded ? undefined : props.style}
                    ellipsesOnHover={props.ellipsesOnHover}
                />
            )}
            {showCustomOrderCallout && (
                <LazyCustomFolderOrderCallout
                    target={rootElementRef ?? accountRef}
                    id={lightningId}
                    accountMailboxInfo={mailboxInfo}
                    delayInMilliseconds={2000}
                />
            )}
            {/* Render rest of the tree if root is expanded */}
            <CSSTransition
                in={isTreeExpanded}
                timeout={200}
                unmountOnExit={true}
                classNames={transitionStyles}
            >
                <div className={animationContainer}>
                    <div className={contentContainer}>
                        <MailFolderListWrapper
                            key={key}
                            treeType={PRIMARY_FOLDERS_TREE_TYPE}
                            isFavoritesSupported={isFavoritesSupported}
                            hasMoreData={!!hasMoreData}
                            shouldShowLoadingSpinner={shouldShowLoadingSpinner}
                            rootFolder={rootFolder as MailFolderFromServiceFragment}
                            rootNodeId={getRootFolderId(props.mailboxInfo)}
                            mailboxInfo={mailboxInfo}
                            moveFolder={moveFolder}
                            offset={offset}
                            fetchMore={fetchMoreData}
                            deleteFolder={deleteFolder}
                            style={props.style}
                            ellipsesOnHover={props.ellipsesOnHover}
                            ariaLabelledBy={rootId}
                        />
                    </div>
                </div>
            </CSSTransition>
            {/* Render Context Menu */}
            <PrimaryMailFolderContextMenu
                isExplicitLogon={!!isExplicitLogon}
                isShadowMailbox={props.isShadowMailbox}
                mailboxInfo={mailboxInfo}
                isExpanded={isTreeExpanded}
                isFavoritesSupported={isFavoritesSupported}
            />
        </>
    );
},
'PrimaryMailFolderTreeContainer');

interface PrimaryMailFolderContextMenuProps {
    isExplicitLogon: boolean;
    isShadowMailbox: boolean;
    mailboxInfo: MailboxInfo;
    isExpanded: boolean;
    isFavoritesSupported: boolean;
}

// Componentization of the context menu helps prevent the whole folder list from re-rendering on ctx menu show/hide
const PrimaryMailFolderContextMenu = observer(function (props: PrimaryMailFolderContextMenuProps) {
    const { mailboxInfo, isExpanded, isFavoritesSupported, isShadowMailbox } = props;
    const shouldShowContextMenu = useComputedValue(() => {
        const { contextMenuState } = getStore();
        // Render the context menu if it is set to be visible and for different folder tree, we render a new context menu
        if (
            !contextMenuState?.folderId ||
            contextMenuState.treeType != PRIMARY_FOLDERS_TREE_TYPE ||
            !doMailboxInfoIndexersMatch(
                folderStore.folderTable.get(contextMenuState?.folderId)?.mailboxInfo,
                mailboxInfo
            )
        ) {
            return false;
        }
        return true;
    }, [mailboxInfo]);

    return shouldShowContextMenu ? (
        <MailFolderContextMenu
            shouldHideToggleFavorite={!isFavoritesSupported}
            treeType={PRIMARY_FOLDERS_TREE_TYPE}
            isShadowMailbox={isShadowMailbox}
            mailboxInfo={mailboxInfo}
            deletedItemsDistinguishedFolderId={PRIMARY_DELETED_ITEMS_DISTINGUISHED_ID}
            primaryMailboxInfo={mailboxInfo}
            isTreeExpanded={isExpanded}
        />
    ) : null;
}, 'PrimaryMailFolderContextMenu');
