import type { RibbonControlGroupDefinitionMLR, RibbonTabDefinitionBase } from 'owa-acui';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, Multiline } from 'owa-command-ribbon';
import loc from 'owa-localize';
import Strings from 'owa-locstrings/lib/strings/ribbon_mail_buttons_and_tabs.locstring.json';
import { getMailRibbonConfigStore } from 'owa-mail-ribbon-store/lib/store/store';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { getUniqueTabId } from 'owa-mail-ribbon-utils/lib/getUniqueTabId';
import type { RibbonControlId } from 'owa-ribbon-ids';
import { type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { getInitialScalingSteps } from '../../../util/getInitialScalingSteps';
import { getMLRScalingSteps } from '../../../util/getScalingSteps';
import { getMLRTabGroups } from '../../../util/getTabGroups';
import { getMLRViewTabCommonGroupFlyout } from './getMLRViewTabCommonGroupFlyout';
import { getMLRViewTabControlDefinition } from './getMLRViewTabControlDefinition';
import { getMLRViewTabGroupLayouts } from './getMLRViewTabGroupLayouts';

const emptyCtrlList: RibbonControlId[] = [];

const getMLRViewTab = owaComputedFn(function getMLRViewTab(
    props: ReadOnlyRibbonControlDefProps
): RibbonTabDefinitionBase {
    return {
        type: Multiline,
        id: getUniqueTabId(3, undefined /* editorId */, props.itemId, props.isPopout),
        title: loc(Strings.view_tab),
        controlGroups: { type: Multiline, groups: getViewTabGroups(props) },
        initialScalingSteps: getInitialScalingSteps(
            getMailRibbonConfigStore(props.mailboxInfo).multiline.viewTab,
            emptyCtrlList /*controlsWithoutLabels*/,
            undefined /*editorId*/,
            undefined /*itemId*/
        ),
        scalingSteps: getMLRScalingSteps(3),
        keytipProps: constructKeytip([], 3, true /* hasMenu */),
    };
});
export default getMLRViewTab;

/**
 * Retrieves the groups within the View tab, as well as the actions that fall under each group.
 * @returns an object that contains the sub-groups and corresponding actions of the View tab
 */
const getViewTabGroups = owaComputedFn(function getViewTabGroups(
    props: ReadOnlyRibbonControlDefProps
): RibbonControlGroupDefinitionMLR[] {
    let viewTabGroups: RibbonControlGroupDefinitionMLR[] = new Array();
    viewTabGroups = getMLRTabGroups(
        3,
        getMailRibbonConfigStore(props.mailboxInfo).multiline.viewTab.layout,
        getMLRViewTabControlDefinition,
        getMLRViewTabGroupLayouts,
        getMLRViewTabCommonGroupFlyout,
        props
    );
    return viewTabGroups;
});
