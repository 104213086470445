import type { ApplicationSettings } from './schema/ApplicationSettings';

/**
 * All application settings should be defined here following the ECS configuration structure.
 * Please insert new settings in alphabetical order, keep a blank line between different settings to preserve readability and reduce chance of merge conflict.
 */
export const defaultApplicationSettings: ApplicationSettings = {
    AboutOutlook: { enabled: false },

    ActionableMessage: {
        resourceUrl: 'https://outlook.office365.com/connectors',
    },

    Addin: {
        devicePermissionAllowList: '',
        isVerboseLogsEnabled: false,
        disableUlsLogging: false,
    },

    AddisonOutage: {
        hasOutage: false,
        outageMessage: '',
        outageLinkUri: '',
    },

    Ads: {
        astJsURL: 'https://acdn.adnxs.com/ast/static/0.51.0/ast.js',
        kvpPageType: 'Outlook_Desktop_Web',
    },

    Analytics: {
        disabledDatapoints: [] as string[],
        throttledDatapoints: [] as string[],
        disableIsCoreDatapoints: [] as string[],
        enableHighCountEventThrottle: false,
        highCountEventThrottleWindow: 15,
        highCountEventThrottleThreshold: 250,
        capturePerfProfiles: [] as {
            name: string;
            expire: number;
        }[],
        maxPerfProfilesPerSession: 25,
    },

    AppHealth: {
        appHealthIntervalInMs: 30 /*minutes*/ * 60 /*seconds*/ * 1000 /*milliseconds*/,
    },

    AppStorePrompt: {
        promptPeriod: 90,
    },

    augloopService: {
        resourceUrl: 'https://augloop.office.com',
    },

    BingNativeAds: {
        //[SuppressMessage("Microsoft.Security", "CS002:SecretInNextLine", Justification="API key that can be snooped fron network traffic. Bing API uses fraud detection to prevent unauthorized use.")]
        placementOther1: 'ARCZfUlvuZ0j9rVyyrDOVrqoWLU1DW5apsssi95fdg/SB0aIpD6l006AvcP/wXoOI40=', // 11706810
        //[SuppressMessage("Microsoft.Security", "CS002:SecretInNextLine", Justification="API key that can be snooped fron network traffic. Bing API uses fraud detection to prevent unauthorized use.")]
        placementOther2: 'ARBR/vpvU+rZ5Amq3JsP/KgfscqG1qZwde2WRFRcGO+P5RfrTrEp2LPDb/01YVw4sF0=', // 11724134
        //[SuppressMessage("Microsoft.Security", "CS002:SecretInNextLine", Justification="API key that can be snooped fron network traffic. Bing API uses fraud detection to prevent unauthorized use.")]
        placementPrimary: 'ARB68RharLDirX5n8PKMAX/gwjPisZPgkOV9uea6yzb7MptvVIFyqLkH0kWrQBTCff8=', // 11725881
        //[SuppressMessage("Microsoft.Security", "CS002:SecretInNextLine", Justification="API key that can be snooped fron network traffic. Bing API uses fraud detection to prevent unauthorized use.")]
        placementHeader: 'ARCyZ6WH59gW6TqsH4dLtbi0U2JLZrEm/ZO3JATMCanRMASaB4TfJmHWe09qcD2AS0g=', // 11725880
    },

    BookingsDomainConfig: {
        domainURL: 'https://outlook.office.com',
    },

    CAPIv3: {
        isCAPIv3Enabled: false,
    },

    CalendarCheckAllowPrivateMeetingScheduling: {
        enabled: false,
    },
    // ECS control: https://ecs.skype.com/Substrate/OutlookWebClient/configurations/1561226
    CalendarFacilitatorDefaultEnablement: {
        allowedTeamsRings: ['ring0'],
    },

    CalendarMacFonts: {
        enabled: false,
    },

    CalendarMeetingApps: {
        meetingPlatform: 'Teams',
    },

    CalendarReplyToAttendees: {
        enabled: true,
    },

    CalendarRibbonNewEventButton: {
        isDropdownEnabled: true,
    },

    CalendarSchedulingPoll: {
        enabled: true,
    },

    CalendarSync: {
        stalenessThresholdInSeconds: 60,
    },

    CalendarTeamsToggle: {
        enabled: false,
        isFeedbackEnabled: false,
    },

    Chapter3AllowList: {
        registrationIds: [],
        sourceContents: [],
    },

    Chapter3BlockedList: {
        registrationIds: [],
        sourceContents: [],
    },

    CLPAudit: {
        resourceUrl: 'https://graph.microsoft.com',
    },

    Copilot: {
        attachmentSummarizationEnabled: false,
        attendanceSuggestionEnabled: false,
        elaborateEnabled: false,
        coachEnabled: false,
        suggestedDraftsEnabled: false,
        summarizeEnabled: false,
        explainPlacesReportEnabled: false,
        replyByMeetingEnabled: false,
        meetingPrepSummaryEnabled: false,
        disableEdgeCopilotExtract: false,
        disableInboxShopping: false,
        disableAddins: false,
        enableMultiLanguage: false,
        calendarElaborateEnabled: false,
        calendarRewriteEnabled: false,
        timeCopilotChatEnabled: false,
        autoResourceBookingEnabled: false,
        prioritizeEnabled: false,
        prioritizeOptInEnabled: false,
        attendeeColocationEnabled: false,
    },

    CopilotAIX00: {
        attachmentSummaryEnabled: false,
        prioritizeEnabled: false,
        elaboratePolarisEnabled: false,
    },

    CopilotPlusPC: {
        supportedLanguages: [],
    },

    CopliotRing: {
        ringName: 'Dogfood',
    },

    Diagnostics: {
        panel: false,
    },

    Dictation: {
        shouldAcquireMicViaNavigator: false,
    },

    DistributionGroups: {
        exchangePortalUrl: 'https://go.microsoft.com/fwlink/?linkid=2236662',
    },

    EnabledResolvers: {
        Query: {},
        Mutation: {},
        Subscription: {},
    },

    EventifyOutage: {
        hasOutage: false,
        outageLinkUri: '',
    },

    EventifySunsetNotice: {
        enabled: false,
        message: '',
        informationLink: '',
        readonlyCutoffUtc: '',
        sunsetCutoffUtc: '',
    },

    ForceReboot: {
        NativeForceRebootEnabled: false,
        WebForceRebootEnabled: false,
    },

    ForwardingWithPopImapOption: {
        enabled: false,
    },

    GdprAds: {
        vendorListCdnUrl: 'https://res.cdn.office.net/assets/ads/7dttl/vendor-list.json',
        vendorListCdnV3Url: 'https://res.cdn.office.net/assets/ads/7dttl/vendor-list-v3.json',
    },

    Graph: {
        resourceUrl: 'https://graph.microsoft.com',
    },

    GWSApi: {
        resourceUrl: 'https://graph.microsoft.com',
    },

    InboxShopping: {
        allowedDomainsURL: 'https:{0}assets/inboxshopping/v1/allowedDomainsBloom.json',
    },

    IntelligentRecap: {
        shouldSwitchHivePayload: false,
        alwaysShowRecapChiclet: false,
        hideAIInsights: false,
    },

    Jana: {
        peopleNovaPeopleApp: true,
        lpeNova: true,
        firstRunExperience: false,
        privateDistributionList: false,
        deletedView: false,
        showMailboxSharedOrDelegate: false,
    },

    LivePersonaCard: {
        allowUrlOverrides: false,
        version: '',
    },

    LivePersonaEditor: {
        enableCategoriesFeature: false,
        enablePersonaSelector: false,
    },

    Loki: {
        resourceUrl: '',
        complianceEnvironment: '',
    },

    LoopAudience: {
        audience: '',
    },

    MeetingTemplates: {
        isTeamsHub: false,
    },

    MeetNow: {
        shouldShowInHeader: true,
        useOwaMailHandler: false,
        useTeamsSDKForJoinMeeting: false,
    },

    MessageRecall: {
        resourceUrl: 'https://outlook.office.com/messagerecallnetcore/',
    },

    MonarchLegacyToggle: {
        delayInSecondsBeforeRenderingFeedback: 2,
    },

    MonarchNotifications: {
        enabled: false,
    },

    MonarchSettingsColumnsFlyout: {
        enabled: false,
    },

    Mos3Urls: {
        TitlesServiceUrl: '',
    },

    MsPlaces: {
        insightsWithMockData: [],
        enableInsightsTab: false,
        enableBuildingAnalyticsTab: true,
        spaceManagementTabEnabled: false,
        enableDeskpoolTab: true,
        enableAdminAvailableOptions: true,
        enableAnalyticsLearning: true,
        enableHelpVideos: true,
        enableAnalyticsMessageBar: true,
        placesAnalyticsCentroBaseUrl: 'https://admin-ignite.microsoft.com', // This is the default value for SDF & Local environment
        enableHomePageSummaryBar: true,
        enableHomePageSummaryCards: true,
    },

    NativeAdIFrame: {
        iframeUrl: 'https://outlookads.live.com/mail/adsframe',
    },

    NativeReactions: {
        deviceType: 'Owa',
    },

    NetCore: {
        enabledAPIs: [],
        disabledAPIs: [],
        shouldCompareResponse: false,
        shouldShadowCall: false,
        shouldUseNetCoreSignalR: false,
        shouldFallback: true,
        authToken: '',
    },

    NewOutlookPromoDialog: {
        storeUrl:
            'ms-windows-store://pdp/?ProductId=9NRX63209R7B&mode=mini&cid=owaPromotionDownload',
    },

    Nps: {
        npsSamplingOwaAppRate: 1,
        npsSamplingOwaSuiteRate: 0.1,
        npsSamplingMonarchAppRate: 4,
        npsSamplingMonarchSuiteRate: 1,
        npsSamplingMonarchCopilotRate: 4,
        npsSamplingOwaCopilotRate: 1,
        npsDialogHeight: '750px',
        npsDialogWidth: '500px',
        anniversary: 1,
        dismissLimit: 3,
        minimumDaysAfterFirstLogon: 7,
        minimumSessionCount: 5,
        anniversaryExperiment: 1,
        anniversaryExperimentPercentage: 100,
        launchDelay: 300000, // 5 minutes
    },

    OcpsPolicyStore: {
        getOcpsPoliciesEndpoint: 'https://clients.config.office.net/user/v1.0/web/policies',
        tokenResourceUrl: 'https://clients.config.office.net',
        defaultPolicies:
            '{"contactSupportEnabled": "1","diagnosticsTroubleshootingEnabled": "1","getDiagnosticsEnabled": "1","emailCollectionEnabled": "1","emailCollectionDefault": "0","feedbackEnabled": "1","logContentEnabled": "1","contentSamplesDefault": "1","npsSurveyEnabled": "1","optionalConnectedExperiencesEnabled": "1","connectedOfficeExperiencesEnabled": "1","outlookRecommendationEnabled": "1","screenshotEnabled": "1","screenshotDefault": "0","loopEnabled": "1","loopEnabledForOutlook": "1","disableSharingPermissions": "0","experiencesDownloadingContentEnabled": "1","experiencesAnalyzingContentEnabled": "1","expirationDate": "","M365LinksHandlingBrowserChoice": "","loopPollsEnabled": "1","showCardLoops": "1","loopPlannerEnabled": "1","loopVideoPlaybackEnabled": "1","loopVideoRecordEnabled": "1"}',
    },

    OneDriveReferral: {
        disableNudgeMessageInAttachmentWell: false,
    },

    OnePersonView: {
        enabled: false,
        newProfileLinkedContactExperience: false,
        enhancedContextualization: false,
    },

    OutlookServiceUrl: {
        outlookServiceDomain: '',
    },

    OwaMonarchReferral: {
        storeUrl: 'ms-windows-store://pdp/?ProductId=9NRX63209R7B&mode=mini&cid=owaMonarchReferral',
    },

    owasmime: {
        cdnContainer: 'owasmime/',
    },

    OwaVanityDomains: {
        enabled: false,
    },

    PDV: {
        peoOfflineTemplate: true,
        peoOfflineDisablePrefetch: true,
        peoOfflineLokiShadow: true,
        peoOfflineInMemorySort: true,
        peoOfflineIndexSort: true,
        peoSubstrateSearch: true,
        peoInvalidateLokiContactCache: true,
    },

    PersonaOffline: {
        resyncIntervalMS: 24 /*hours*/ * 60 /*minutes*/ * 60 /*seconds*/ * 1000 /*milliseconds*/,
        pgalSyncDelayInMilliSeconds: 60 * 1000 /* = 60 seconds */,
        pgalModuleSyncPageSize: 250,
        contactsModuleSyncPageSize: 1000,
        maxCountToSync: 30000, // TODO @kamcheung -same setting as Teams. Honour when https://msfast.visualstudio.com/FAST/_workitems/edit/560707 is implemented
    },

    PlacesAnalyticsHelpVideosUrls: {
        ViewBuildingAnalyticsVideoUrl:
            'https://www.microsoft.com/en-us/videoplayer-nocookie/embed/RW1lhw3?pid=ocpVideo1&maskLevel=20&market=en-us',
        SwitchLocationsVideoUrl:
            'https://www.microsoft.com/en-us/videoplayer-nocookie/embed/RW1leYm?pid=ocpVideo3&maskLevel=20&market=en-us',
        ChangeDateRangeVideoUrl:
            'https://www.microsoft.com/en-us/videoplayer-nocookie/embed/RW1lhw8?pid=ocpVideo2&maskLevel=20&market=en-us',
        ApplyFiltersVideoUrl: '',
        ConfigureWorkDaysVideoUrl:
            'https://www.microsoft.com/en-us/videoplayer-nocookie/embed/RW1lhwt?pid=ocpVideo5&maskLevel=20&market=en-us',
        ExplainDataUsingCopilotVideoUrl: '',
        ShareReportsVideoUrl:
            'https://www.microsoft.com/en-us/videoplayer-nocookie/embed/RW1ljLf?pid=ocpVideo4&maskLevel=20&market=en-us',
    },

    PlacesOutage: {
        hasOutage: false,
        outageLinkUri: '',
        outageEnumIdMessage: 0,
        outageCustomMessage: '',
        typeMessageVariant: 0,
    },

    PlatformType: {
        isMonarch: false,
    },

    PoisonedBuild: {
        skipBuilds: [],
    },

    PreAuthShellResourceUrls: {
        webShellResourceUrl: 'https://webshell.suite.office.com',
        graphResourceUrl: 'https://graph.microsoft.com',
        officeApiResourceUrl: 'https://api.office.net',
    },

    PrepareModule: {
        enabled: false,
    },

    PrivacySettings: {
        enabled: false,
        roamingEnabled: false,
    },

    RebootOnSettingsChange: { enabled: false },

    RecapDeeplink: {
        phase0Enabled: false,
    },

    ReliabilityCheck: {
        isPresent: false,
    },

    RsvpSuggestions: {
        meetingImportanceForAccept: 8,
        meetingImportanceForDecline: 3,
    },

    Safelinks: {
        staticPageBaseUrl: 'https://outlook.office.com/mail/safelink.html',
        getServiceLocatorHost: 'https://na01.safelinks.protection.outlook.com',
        isSupported: true,
    },

    SafelinksURLConfigs: {
        SafelinksHostnames:
            'safelinks.protection.com,safelinks.protection.office365.us,safelinks.protection.outlook.com,safelinks.protection.outlook.cn,safelinks.protection.outlook.de,safelinks.o365filtering-int.com',
        SpoLofHostnames:
            'sharepoint-df.com,sharepoint.com,sharepoint.de,sharepoint.cn,sharepoint-mil.us,sharepoint.us',
        GetParams: 'url',
        DropParams: 'data,sdata,reserved',
        SpoLofDropParams: 'xsdata,sdata,ovuser,clickParams',
    },

    SettingsUxOutboundLinks: {
        showLinkPreviewLinks: true,
        showMobileGetAppLink: true,
        showPopImapSupportLink: true,
        showRulesInfoLink: true,
        showSmimeChromeExtensionLinks: true,
    },

    SignalR: {
        LoggingDelay: 30000,
        ChannelsToCompareWith: {
            OwaClassicSignalR: ['OwaNetCoreSignalR', 'ONOSSignalR'],
            OwaNetCoreSignalR: [],
        },
    },

    SuggestedAttachmentsRecommendationServiceCall: {
        ScenarioName: 'OutlookWebMonarch',
    },

    SuggestedAttachmentsSuppression: {
        enabled: false,
    },

    SupportAndFeedback: {
        policyEnabledIfUndefined: false,
    },

    SwCache: {
        successRatio: 1,
        chunkSize: 100,
    },

    SydneyEndpoint: {
        cafe: 'https://substrate.office.com/m365chat/Chathub',
        cortana: 'https://api.cortana.ai/m365chat/SecuredChatHub',
        avalon: 'https://substrate.office.com/m365Copilot/Chathub',
        // avalon sdf-pilot: 'https://sdf-s01-00-nam-westus.substrate.cosmic-ppe.office.net/m365Copilotcanary/Chathub'
        // avalon sdf: 'https://substrate.office.com/m365Copilot/Chathub'
    },

    TeamsArtifactsService: {
        serviceName: 'Teams Artifacts',
        serviceBaseUrl: '',
        serviceResourceUrl: 'https://teams.cloud.microsoft/6bc3b958-689b-49f5-9006-36d165f30e00',
    },

    IC3ActiveMeetingsService: {
        serviceName: 'IC3 Active Meetings Service',
        serviceBaseUrl: '',
        serviceResourceUrl: '',
    },

    TeamsAuth: {
        serviceName: 'Teams Auth Service',
        serviceBaseUrl: 'https://teams.microsoft.com/api/authsvc',
        serviceResourceUrl: 'https://api.spaces.skype.com',
    },

    TeamsMiddleTier: {
        serviceName: 'Teams Middle-Tier',
        serviceBaseUrl: '',
        serviceResourceUrl: 'https://api.spaces.skype.com',
    },

    TeamsMiddleTierS2S: {
        serviceName: 'Teams Middle-Tier S2S',
        serviceBaseUrl: '',
        serviceResourceUrl: 'https://api.spaces.skype.com',
    },

    TeamsURL: {
        resourceURL: '',
    },

    TeamsVirtualEvents: {
        serviceName: 'Teams Virtual Events',
        serviceBaseUrl: '',
        serviceResourceUrl: 'https://events.teams.cloud.microsoft',
    },

    TipsTour: {
        enabled: false,
    },

    ToDo: {
        isToDoFeaturesEnabled: true,
    },

    Topics: {
        loadSDK: true,
    },

    TrouterConfig: {
        TrouterServiceUrl: 'https://go.trouter.teams.microsoft.com/',
        RegistrarServiceUrl: 'https://teams.microsoft.com/registrar/prod/',
    },

    UniversalMeControl: {
        enabled: true,
    },

    Upsell: {
        adsFreeUpsellUrl: 'https://go.microsoft.com/fwlink/?linkid=2222261',
        adBlockerUpsellUrl: 'https://go.microsoft.com/fwlink/?linkid=2222646',
        euAdPromptUrl: 'https://go.microsoft.com/fwlink/?linkid=2222558',
        adFreeOnNativeAdUrl: 'https://go.microsoft.com/fwlink/?linkid=2192065',
        nativeAdDefaultAdUrl: 'https://go.microsoft.com/fwlink/?linkid=2222260',
        usqLeftNavUrl: 'https://go.microsoft.com/fwlink/?linkid=2219821',
        usqBizBarUrl: 'https://go.microsoft.com/fwlink/?linkid=2219723',
        usqStorageSettingUrl: 'https://go.microsoft.com/fwlink/?linkid=2219722',
    },

    Urlp: {
        resourceUrl: 'https://urlp.asm.skype.com',
        endpointUrl: 'https://urlp.asm.skype.com/v1/url/info?url=',
    },

    WebPushNotifications: {
        disabled: false,
    },

    XandrNativeAds: {
        placementOther1: 'WAN1USEN',
        placementOther2: 'WAN2GBL',
        placementPrimary: 'PWP1GBL',
        placementHeader: 'PWN1GBL',
        placementNonInbox: 'PCWeb_NonInbox',
        placementFloatingInbox: 'PCWeb_Float_Primary',
        placementFloatingOther: 'PCWeb_Float_Other',
        placementOther12ndCall: 'SECWAN1USEN',
        placementOther22ndCall: 'SECWAN2GBL',
        placementPrimary2ndCall: 'SECPWP1GBL',
        placementHeader2ndCall: 'SECPWN1GBL',
        placementNonInbox2ndCall: 'SECPCWeb_NonInbox',
        placementFloatingInbox2ndCall: 'SECPCWeb_Float_Primary',
        placementFloatingOther2ndCall: 'SECPCWeb_Float_Other',
        placementFocusInbox: 'PCWeb_FocusedInbox',
        placementFocusInbox2ndCall: 'SECPCWeb_FocusedInbox',
    },

    UrlValidationSettings: {
        whiteListedSchemas: [],
    },
};
