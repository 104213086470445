import type { MailboxInfo } from 'owa-client-types';
import { isSameMailboxInfo } from 'owa-account-source-list-store';
import { isSameCoprincipalAccountMailboxInfos } from 'owa-client-types';
import { isMailboxSharedOrDelegate } from 'owa-account-shared-mailbox-utils';
import { checkMailboxesAreSupportedForCrossAccount } from '../actions/helpers/isMoveCopyCrossAccountSupported';
export type MoveCopyType = any;

export function getMoveCopyType(
    sourceMailboxInfo: MailboxInfo,
    destinationMailboxInfo: MailboxInfo
) {
    // Same mailbox is local operation
    if (isSameMailboxInfo(sourceMailboxInfo, destinationMailboxInfo)) {
        // This is to handle Archive to Archive, since it can have an Archive->AuxArchive (or vice-versa) configuration
        if (
            sourceMailboxInfo.type === 'ArchiveMailbox' &&
            destinationMailboxInfo.type === 'ArchiveMailbox' &&
            sourceMailboxInfo.auxiliaryMailboxGuid !== destinationMailboxInfo.auxiliaryMailboxGuid
        ) {
            // If the MailboxGuid are different, then it is a CrossForest
            return 2;
        }

        return 1;
    }

    // Same coprincipal mailboxes and shared mailbox as accounts/delegate are cross forest
    if (
        isSameCoprincipalAccountMailboxInfos(sourceMailboxInfo, destinationMailboxInfo) ||
        isMailboxSharedOrDelegate(sourceMailboxInfo) ||
        isMailboxSharedOrDelegate(destinationMailboxInfo)
    ) {
        return 2;
    }

    // Check if the mailboxes are supported for Move/Copy cross account
    if (checkMailboxesAreSupportedForCrossAccount(sourceMailboxInfo, destinationMailboxInfo)) {
        return 3;
    }

    return 0;
}

export function isMoveCopyAllowed(
    sourceMailboxInfo: MailboxInfo,
    destinationMailboxInfo: MailboxInfo
) {
    return getMoveCopyType(sourceMailboxInfo, destinationMailboxInfo) !== 0;
}

// if this is considered a remote mailbox request
export function isRemoteMailboxCallRequest(
    sourceFolderMailboxInfo: MailboxInfo,
    destinationFolderMailboxInfo: MailboxInfo
) {
    return getMoveCopyType(sourceFolderMailboxInfo, destinationFolderMailboxInfo) === 2;
}

export function shouldForceItemOperation(
    sourceMailboxInfo: MailboxInfo,
    destinationMailboxInfo: MailboxInfo
) {
    const moveCopyType = getMoveCopyType(sourceMailboxInfo, destinationMailboxInfo);
    return moveCopyType === 3 || moveCopyType === 2;
}
